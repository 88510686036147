/* style for pagination START */

.pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-top: 0px !important;
}

.pagination>li {
    display: inline;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    cursor: not-allowed;
    color: #000000;
    background-color: #fff;
    border-color: #86868a;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: 0.2rem;
    border-top-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}

.paginationBar {
    width: 100%;
    margin: 6px auto;
    text-align: center;
}

.paginationArea {
    display: inline-block;
}

.pageSizeArea {
    margin-left: 8px;
    display: inline-block;
    color: inherit;
    font-weight: normal;
    font-size: 12px;
}

/* style for pagination END */

.LiUserTable_header {
    width: 100%;
    color: #881B4C;
    font-weight: bold;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height:32px;
    padding-right: 2px;
    /* border: 1px solid red; */
}

.LiUserTable_headerLink {
    color: #213e5c;
    font-weight: bold;
    cursor: pointer;
}

.LiUserTable_container {
    width: 100%;
    height: 50%;
    text-align: center;
}

.searchConditions {
    display: inline-block;
    float: left;
    margin-top: 14px;
    margin-left: 60px;
}

.react-swipeable-view-container>div {
    background-color: #e4dcdc;
}

.LiUserTable_basic_search_area {
    padding-bottom: 5px;
    padding-right: 5px;
    width: inherit;
}

.LiUserTable_basic_search_area>div {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.LiUserTable_advanced_search_area {
    background-color: #e4dcdc;
    padding-bottom: 5px;
}

.LiUserTable_addmore {
    text-align: center;
}

.LiUserTable_searchRow {
    background-color: #e4dcdc;
    width: 100%;
    display: inline-block;
    height: 60px !important;
    border-bottom: 1px solid white;
}

.LiUserTable_searchSpan1 {
    padding: 0 2px;
    width: 10%;
    display: inline-block;
    text-align: left;
}

.LiUserTable_searchRow .LiUserTable_searchSpan2 {
    display: inline-block;
    text-align: left;
    float: left;
    padding-left: 15px;
}

.LiUserTable_searchRow .LiUserTable_searchSpan3 {
    padding: 0 2px;
    width: 25%;
    float: left;
    display: inline-block;
    text-align: left;
    margin-top: 16px;
}

.LiUserTable_searchText {
    width: 100%;
}

.LiUserTable_searchCombo2 {
    width: 100% !important;
    height: 60px !important;
}

#LiUserTable_searchTab div button div {
    height: 32px !important;
}

#LiUserTable_searchTab div button {
    text-transform: none !important;
    height: 32px !important;
}

.LiUserTable_sortShowHide {
    display: none;
    height: 30px !important;
}

.LiUserTable_sortShowHide>div {
    height: 100% !important;
}

.LiUserTable_sortShowHide>button {
    height: 100% !important;
}

.LiUsersTable_sortDropDown div div {
    line-height: 2 !important;
}

.LiUsersTable_sortDropDown div button {
    height: 100% !important;
    padding-top: 0 !important;
}

.LiUserTable_headerColumn {
    padding-left: 8px !important;
    padding-right: 8px !important;
    background-color: rgb(208, 213, 218, 1) !important;
    text-align: center;
    word-break: break-all !important;
    white-space: nowrap !important;
}

.LiUserTable_noData {
    text-align: center;
    background-color: rgb(65, 64, 66, 0.1) !important;
}

.LiUserTable_headerColumn a {
    text-decoration: none !important;
}

.LiUserTable_rowColumn {
    padding-left: 8px !important;
    padding-right: 8px !important;
    text-align: left !important;
    word-break: break-all !important;
    white-space: nowrap !important;
}

.LiUserTable_searchContainer {
    background-color: #e4dcdc;
}

div.userTrialClass>div {
    overflow-x: auto;
}

.imageIcon {
    height: 40px !important;
}

.LiUserTable_actionButton {
    margin-top: -33px !important;
    margin-left: -15px !important;
    width: inherit !important;
    padding-top: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 33px !important;
}

.noCursorForTrigerMenu{
    cursor: default !important;
}

.liInfoTable {
    width: 100%;
}

.SearchArea>div>div:nth-child(2)>div>div:nth-child(1) {
    overflow-x: hidden !important;
}

.SearchArea>div>div:nth-child(2)>div>div:nth-child(2) {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    margin-bottom: 10px !important;
}

.LiUserTable_searchCombo2>label {
    text-decoration: underline;
}

.LiTable_highlight {
    background-color: lightblue !important;
}

.actionTriggerPopup {
    color: #ffffff !important;
    /* background-color: #3c454b !important; */
    background-color: var(--darktheme-white-variant5) !important;
    padding: 5px !important;
    height: 22px !important;
}

.LightTheme.actionTriggerPopupClickable {
    color: var(--white) !important;
    min-height: 30px !important;
    /* background-color: #D3D3D3 !important; */
    background-color: var(--darktheme-white-variant5)!important;
}

.DarkTheme.actionTriggerPopupClickable {
    color: var(--black) !important;
    min-height: 30px !important;
    /* background-color: #30373d !important; */
    background-color: var(--lighttheme-black-variant4) !important;
}

.LightTheme.actionTriggerPopupClickable:hover {
    /* background-color: var(--claret-violet) !important; */
    background-color: var(--lighttheme-tufts-blue-variant1) !important;
    color: var(--white) !important;
}

.DarkTheme.actionTriggerPopupClickable:hover {
    background-color: var(--darktheme-tufts-blue-variant2) !important;

}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.actionTriggerPopupClickable div div {
    line-height: 30px !important;
}

span.LightTheme.actionTriggerPopupClickable {
    /* background-color: #D3D3D3 !important; */
    background-color: var(--darktheme-white-variant5) !important;
}

div[role="menu"] {
    padding: 0px 0px !important;
}

div[role="presentation"] {
    width: 100% !important;
}

.actionTriggerType {
    width: 100%;
    background-color: #3c454b !important;
    display: inline-block;
}

img.LiTrigger_typeIcon {
    float: left;
    width: 50%;
    margin: 2px !important;
}

.actionTriggerPopup {
    float: left;
}

tr.LiUserTable_row {
    border-bottom: 1px solid white !important;
    height: 30px !important;
}

table.LiTableRowBackGround {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border: 1;
}

tr.LiTableRowBackGround {
    border-bottom: 3px solid white !important;
    height: 30px !important;
}

tr.LiTableRowBackGround_Search {
    height: 30px !important;
}

.LiTable_statusColumn_42 {
    float: left;
    width: 35%;
}

.LiTable_statusColumn_42 img {
    width: 42px;
}

.LiTable_statusColumn_24 img {
    width: 24px;
}

/* css for table general info starts */

.generalInfoPnl {
    width: 100%;
}

.generalTblPnlOuter {
    width: 100%;
}

/* css for table general info ends here */

.addSearchButton {
    color: blue;
    margin-left: 15px;
}

.LiUserTable_searchSpan2 {
    width: 25% !important;
}

.LiUserTable_searchSpan1 {
    width: 10%;
    margin-top: 15px;
}

.LiUserTable_searchSpan3 .searchText {
    max-width: 100% !important;
}

.search_combo_1 {
    top: 16px !important;
}

.LiUserTable_addmore .searchButton {
    margin-top: 15px;
    display: inline-block;
    margin-left: 20px;
    float: left;
}

.LiUserTable_addmore .searchButton .LiGeneral_actionButton, .addSearchButton {
    border-radius: 10px !important;
}

.addSearchButton {
    margin-left: 10px !important;
}

.LiGeneralSaveActionButton {
    /* background-color: #881B4C !important; */
    color: #ffffff !important;
    padding: 2px !important;
    /* border-radius: 10px !important; */
    margin-top: 0px !important;
    margin-left: 50px !important;
    float: right;
}

.LiUserTable_searchCombo2 label {
    top: 30px !important
}

.searchHistoryIcon {
    height: 30px;
    width: 35px;
    vertical-align: middle;
    cursor: pointer;
}

.seachHistoryList {
    width: 200px;
    margin-bottom: 10px !important;
}

.addNameTxtField {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    width: 200px !important;
    float: left;
}

/************************************** For Testing Purpose Only **************************************************************/

.LiIconComponent_icon {
    margin-top: 0px !important;
}

input#LiDate_datePicker {
    width: 70px !important;
}

/***********************************************************************************************************************************/

@media print {
    @page {
        size: landscape;
    }
}

.saveSearchButton {
    display: none;
    border: 2px solid #881B4C;
}

#saveSortButton {
    display: none;
    /* padding-top: 30px; */
    padding-left: 20px;
}

.searchCommonDiv {
    display: block;
    height: 60px;
    width: 100%;
}

.deleteIcon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.LiUserTable_addmore {
    background-color: #e4dcdc !important;
}

.LiUserTable_searchCombo2>div {
    margin-top: 1px !important;
}

.SearchLabel {
    /* width: 30%; */
    float: left;
    margin-top: 12px;
    margin-right: 36px !important;
}

.seachHistoryList {
    width: 100%;
    display: inline-block !important;
}

.seachHistoryList>div {
    min-width: 40% !important;
    margin-top: 5px !important;
    float: left;
}

.deleteSearch {
    background-color: white;
    float: right;
    height: 25px;
    width: 25px;
    margin-top: 10px;
}

.namesLabel {
    display: inline-block;
    margin-top: 8px;
}

.showSearch, .showSort {
    height: 25px;
    width: 25px;
    display: inline-block;
    float: left;
    margin-top: 5px;
    margin-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.showAdvanceSearch, .showSaveSort {
    display: inline-block;
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    margin-bottom: 0px;
    cursor: pointer;
}

.showSearchDiv, .showSortDiv {
    width: 100%;
    /* display: inline-block; */
    display: flex;
    /* height: 25px !important; */
    margin-bottom: -20px;
}

#sortListItems {
    margin-left: -42px;
    margin-bottom: 10px;
    /* padding-bottom: 25px; */
    /* overflow-y: auto; */
    /* height: 300px; */
}

#sortListItems ul {
    list-style: none;
    list-style-type: none;
}

.saveSort {
    width: 100%;
    display: inline-block;
    /* position: fixed; */
}

.applySortButton, .closeButton {
    background-color: #881B4C !important;
    color: #ffffff !important;
    float: right;
    /* border-radius: 10px !important; */
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    height: 35px !important;
}

img.LiTable_searchOption {
    width: 24px;
    cursor: pointer;
    margin-left: -5px;
}

img.LiTable_ColsearchOption {
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
}

.LiTable_expand {
    cursor: pointer;
    width: 20px;
}

span.LiHeader_image {
    width: 24px;
    height: 24px;
}

span.LiHeader_image img {
    width: 24px;
    height: 24px;
}

.UDSlist {
    /* overflow-y: auto; */
}

.LiCamparator {
    float: left;
    width: 25% !important;
}

.LiDatePicker_style input {
    margin-top: 0px !important;
}

img.static_filter_action {
    width: 20px;
    margin-left: 2px;
    margin-right: 3px;
    cursor: pointer;
}

img.subTableIcon {
    width: 15px;
    height: 15px;
    margin-left: 2px;
    margin-right: 3px;
    cursor: pointer;
}

.card {
    width: 100%;
    max-width: 100%;
}

.container {
    width: 100%;
    margin: 0.25em auto;
    display: contents;
    background: rgb(13, 169, 221)
}

.subtableRow {
    width: 100%;
    height: 100%;
}

.subRowColumn {
    display: contents;
}

#tableDemo {
    width: 100%;
    height: 100%;
}

.pageIcon {
    width: 20px;
    height: 12px;
}

.LiTable_Sort {
    width: 8px;
    height: 6px;
}

.SortArrows {
    display: inline-block;
    float: right;
    margin-top: -6px;
}

.TopArrow {
    height: 10px;
    position: absolute;
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
}

.DownArrow {
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid transparent;
}

.LiTableRowBackGround_searchdisable {
    display: none;
}

#tableDemo>div:nth-child(n)>div>table>thead>tr:nth-child(n)>th:nth-child(n)>div>a>div:nth-child(n)>label {
    padding-top: 5px !important;
    cursor: pointer;
}

.LiUserTable_headerColumn.actionColumn>div>div>button>div>span {
    padding-top: 31px !important;
    padding-left: 10px !important;
}

table>tbody>tr:nth-child(n)>td:nth-child(n)>label {
    float: left !important;
}

body::-webkit-scrollbar-thumb {
    /* background: #4080C1 !important; */
    background: var(--darktheme-tufts-blue) !important;
}

/************************************************ Dark & Light Themes *********************************************************/

.DarkTheme.LiUserTable_headerColumn {
    padding-left: 3px !important;
    padding-right: 3px !important;
    background-color: var(--darktheme-pri-color) !important;
    text-align: center;
    word-break: normal !important;
    height: 30px !important;
    font-size: 16px;
    letter-spacing: 0;
    color: #E8E9EA;
    opacity: 1;
}

.DarkTheme.LiUserTable_rowColumn {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    text-align: left !important;
    height: 20px !important;
    word-break: normal !important;
    max-height: 100% !important;
    max-width: 200px !important;
    font-size: 13px;
    letter-spacing: 0;
    /* color: var(--darktheme-white-variant1); */
    opacity: 1;
    background-color: rgb(65, 64, 66, 0.1) !important;
}
tr.DarkTheme.LiUserTable_row{
    color: var(--darktheme-white-variant1) !important;
}

tr.LiUserTable_row.LiTable_active {
    color: white !important;
}

.DarkTheme.LiUserTable_headerLink {
    color: #E8E9EA!important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.DarkTheme.LiUserTable_headerLink_ID {
    /* color: #4080C1 !important; */
    color: var(--darkTheme-highlightColor) !important;
}

.DarkTheme.LiUserTable_rowColumn:hover .DarkTheme.LiUserTable_headerLink_ID {
    /* color: #FFFFFF !important; */
    color: var(--darkTheme-highlightColor) !important;
    /* font-weight: 900 !important; */
    /* font-size: larger !important; */
    cursor: pointer !important;
}

.DarkTheme.LiUserTable_header_a {
    color: var(--darktheme-white-variant1);
    font-weight: bold;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.DarkTheme.paginationBar {
    width: 100%;
    height: 25px;
    background-color: var(--darktheme-black-variant1);
    margin: 6px auto;
    text-align: right;
    padding-top: 5px;
    color: white;
}

.DarkTheme.pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-top: 0px !important;
}

.DarkTheme.LiTable {
    border-collapse: unset !important;
    /* background-color: #31393F !important; */
    background-color: var(--darktheme-black-variant2) !important; /*new color code change*/
}

/****************** Alternate Row Colors for Table & Subtable *********************/

body .DarkTheme.LiTable tbody tr:nth-child(even) {
    /* background: #30373d; */
    background-color: var(--darktheme-black-variant2);    /*new color code change*/
}

body .DarkTheme.LiTable tbody tr:nth-child(odd) {
    /* background: #353D44; */
    background-color: var(--darktheme-black-variant1);    /*new color code change*/
}

body .DarkTheme.subtableRow tbody tr:nth-child(even) {
    background-color: #30373d;
}

body .DarkTheme.subtableRow tbody tr:nth-child(odd) {
    background-color: #3C454D;
}

.DarkTheme.pageSizeCombo {
    width: 50px;
    height: 20px;
    padding: 2px 7px;
    border: 1px solid white;
    background: url("./down_arrow_dark_theme.svg") no-repeat scroll right center;
    background-size: 20px 10px;
    -webkit-appearance: none;
    color: white;
    background-color: #30373D;
}

.DarkTheme.pagination>li>a, .DarkTheme.pagination>li>span, .DarkTheme.pagination>li>span>label {
    position: relative;
    padding: 6px 10px;
    line-height: 1.42857143;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.DarkTheme.pagination>li>a:hover {
    /* background-color: #4080C1; */
    background-color: var(--darktheme-tufts-blue);
    border-radius: 50%;
}

.DarkTheme.pagination>.active>a {
    z-index: 3;
    cursor: pointer;
    /* color: #4080C1; */
    color: var(--darktheme-tufts-blue);
    /* border: 1px solid #4080C1; */
    border: 1px solid var(--darktheme-tufts-blue);
    background-color: var(--darktheme-pri-color);
}

.DarkTheme.pagination>.active>a:hover {
    /* border: 1px solid #4080C1; */
    border: 1px solid var(--darktheme-tufts-blue);
    background-color: var(--darktheme-pri-color);
    border-radius: 0%;
}

.DarkTheme.pagination>.disabled>a, .DarkTheme.pagination>.disabled>a:focus, .DarkTheme.pagination>.disabled>a:hover, .DarkTheme.pagination>.disabled>span, .DarkTheme.pagination>.disabled>span:focus, .DarkTheme.pagination>.disabled>span:hover, .DarkTheme.pagination>.disabled>a:hover, .DarkTheme.pagination>.disabled>span>label:hover {
    cursor: not-allowed !important;
    opacity: 0.25;
    color: white;
    background-color: var(--darktheme-pri-color);
}

.DarkTheme.material-icons {
    padding-top: 20px !important;
    padding-left: 10px !important;
    /* color: white !important; */
}

.DarkTheme.LiTable>thead>tr:nth-child(n)>th:nth-child(1) {
    height: 30px !important;
}

.DarkTheme.LiTable>thead>tr:nth-child(odd)>th:nth-child(n) {
    background-color: var(--darktheme-black-variant1) !important;    /*new color code change*/
}

.DarkTheme.LiTable>thead>tr:nth-child(even)>th:nth-child(n) {
    /* background-color: #31393F !important; */
    background-color: var(--darktheme-black-variant2) !important;    /*new color code change*/
}

.DarkTheme.LiTable>tbody>tr:nth-child(even)>td:nth-child(1) {
    /* background-color: #31393F !important; */
    height: 30px !important;
}

.DarkTheme.LiTable>tbody>tr:nth-child(odd)>td:nth-child(1) {
    /* background-color: inherit !important; */
    height: 30px !important;
}

.DarkTheme.LiTable>thead>tr:nth-child(2)>th:nth-child(1)>div>div>div>div:nth-child(1)>svg, .DarkTheme.LiTable>tbody>tr:nth-child(n)>td:nth-child(1)>div>div>div>div:nth-child(1)>svg {
    fill: #EFF2F4 !important;
}

.DarkTheme.LiUserTable_rowColumn.LiTable_active {
    background: var(--tufts-blue) !important;
    /* background: var(--darkTheme-highlightColor) !important; */
    /* color:black !important */
}



.DarkTheme.UDlist>div>span>span, .DarkTheme.UDSlist>div>span>span {
    color: white !important;
}

/**********************************************************LIGHT THEME ***************************************************************/

.LightTheme.LiUserTable_headerColumn {
    padding-left: 3px !important;
    padding-right: 3px !important;
    /* background-color: #d9d9d9 !important;   */
    background-color: var(--lighttheme-black-variant3) !important;    /*new color code change*/
    text-align: center;
    word-break: normal !important;
    height: 30px !important;
    font-size: 16px;
    letter-spacing: 0;
    color: #26404F;
    opacity: 1;
}

.LightTheme.LiUserTable_rowColumn {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    text-align: left !important;
    height: 20px !important;
    word-break: normal !important;
    max-height: 100% !important;
    max-width: 200px !important;
    font-size: 13px;
    letter-spacing: 0;
    opacity: 1;
    background-color: rgb(65, 64, 66, 0.1) !important;
}

.LightTheme.LiUserTable_headerLink {
    color: black !important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.LightTheme.LiUserTable_header_a {
    color: #353D44;
    font-weight: bold;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.LightTheme.LiUserTable_rowColumn:hover .LightTheme.LiUserTable_headerLink_ID {
    color: black !important;
    /* font-weight: 900 !important;
    font-size: larger !important; */
    cursor: pointer !important;
}

.LightTheme.LiUserTable_headerLink_ID {
    color: #881B4C !important;
}

.LightTheme.paginationBar {
    width: 100%;
    height: 25px;
    /* background-color: #E6E7E6; */
    background: var(--lighttheme-black-variant3);  /*new color code change*/
    margin: 6px auto;
    text-align: right;
    padding-top: 5px;
    color: black;
}

.LightTheme.pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-top: 0px !important;
}

.LightTheme.LiTable {
    border-collapse: unset !important;
    /* background-color: #E2E2E2!important; */
    background-color: var(--lighttheme-black-variant5) !important;    /*new color code change*/
}

/**************** Alternate Row Colors for Table & Subtable ***************************/

body .LightTheme.LiTable tbody tr:nth-child(even) {
    /* background: #E2E2E2 */
    background: var(--lighttheme-black-variant5)  /*new color code change*/
}

body .LightTheme.LiTable tbody tr:nth-child(odd) {
    /* background: #F4F4F4 */
    background: var(--lighttheme-black-variant3)  /*new color code change*/
}

body .LightTheme.subtableRow tbody tr:nth-child(odd) {
    background: var(--lighttheme-black-variant3)  /*new color code change*/
}

body .LightTheme.subtableRow tbody tr:nth-child(even) {
    background: #E2E2E2
}

.LightTheme.pageSizeCombo {
    width: 50px;
    height: 20px;
    padding: 2px 7px;
    border: 1px solid #61666B;
    background: url("./down_arrow_light_theme.svg") no-repeat scroll right center;
    background-size: 20px 10px;
    -webkit-appearance: none;
    color: #61666B;
    background-color: #E6E7E6;
}

.LightTheme.pagination>li>a, .LightTheme.pagination>li>span, .LightTheme.pagination>li>span>label {
    position: relative;
    padding: 6px 10px;
    line-height: 1.42857143;
    cursor: pointer;
    text-decoration: none;
    color: #61666B;
    /* background-color: #E6E7E6; */
}

.LightTheme.pagination>li>a:hover {
    background-color: rgba(136, 27, 76, 0.25);
    border-radius: 50%;
    color: #61666B;
}

.LightTheme.pagination>.active>a {
    z-index: 3;
    cursor: pointer;
    color: #872658;
    border: 1px solid #872658;
    background-color: #E6E7E6;
}

.LightTheme.pagination>.active>a:hover {
    border: 1px solid #872658;
    background-color: #E6E7E6;
    border-radius: 0%;
}

.LightTheme.pagination>.disabled>a, .LightTheme.pagination>.disabled>a:focus, .LightTheme.pagination>.disabled>a:hover, .LightTheme.pagination>.disabled>span, .LightTheme.pagination>.disabled>span:focus, .LightTheme.pagination>.disabled>span:hover, .LightTheme.pagination>.disabled>a:hover, .LightTheme.pagination>.disabled>span>label:hover {
    cursor: not-allowed !important;
    opacity: 0.5;
    color: #61666B;
    background-color: #E6E7E6;
}

.LightTheme.material-icons {
    padding-top: 20px !important;
    padding-left: 10px !important;
    color: black !important;
}

.LightTheme.LiTable>thead>tr:nth-child(n)>th:nth-child(1) {
    background-color: var(--lighttheme-black-variant3) !important;
    height: 20px !important;
}

.LightTheme.LiTable>thead>tr:nth-child(even)>th:nth-child(n) {
    /* background-color: #E2E2E2 !important; */
    background-color: var(--lighttheme-black-variant4) !important;   /*new color code change */
}

.LightTheme.LiTable>tbody>tr:nth-child(odd)>td:nth-child(1) {
    /* background-color: #F4F4F4 !important; */
    /* background-color: var(--lighttheme-black-variant3) !important;   /*new color code change */
    height: 20px !important;
}

.LightTheme.LiTable>tbody>tr:nth-child(even)>td:nth-child(n) {
    /* background-color: #E2E2E2 !important; */
    /* background-color: var(--lighttheme-black-variant5) !important; */
    height: 20px !important;
}

.LightTheme.LiTable>thead>tr:nth-child(2)>th:nth-child(1)>div>div>div>div:nth-child(1)>svg:nth-child(1), .LightTheme.LiTable>tbody>tr:nth-child(n)>td:nth-child(1)>div>div>div>div:nth-child(1)>svg:nth-child(1) {
    fill: black !important;
}

.LightTheme.LiTable>thead>tr:nth-child(2)>th:nth-child(1)>div>div>div>div:nth-child(1)>svg:nth-child(2), .LightTheme.LiTable>tbody>tr:nth-child(n)>td:nth-child(1)>div>div>div>div:nth-child(1)>svg:nth-child(2) {
    fill: white !important;
}

/*********************** TableRow Selection *****************************/

.LightTheme.LiUserTable_rowColumn.LiTable_active {
    /* background: var(--claret-violet) !important; */
    background: var(--tufts-blue) !important;
    color:var(--white) !important;
}
.LiTable_active{
    background: var(--tufts-blue) !important;
}

.LightTheme.LiTable>tbody>tr:nth-child(odd)>td:nth-child(1).LiTable_active {
    /* background-color: var(--claret-violet) !important; */
    background-color: var(--tufts-blue) !important;
    color:var(--white) !important;
}

.LightTheme.LiTable>tbody>tr:nth-child(even)>td:nth-child(n).LiTable_active {
    /* background-color: var(--claret-violet) !important; */
    background-color: var(--tufts-blue) !important;
    color:var(--white) !important;
}

.LightTheme.UDlist>div>span>span, .LightTheme.UDSlist>div>span>span {
    color: black !important;
}

tr.DarkTheme.LiUserTable_row.maintableRow.LiTable_active > td {
    background: var(--tufts-blue) !important;
    border-bottom: 1px solid var(--darktheme-white-variant1) !important;
    color: var(--white) !important;
}

tr.LightTheme.LiUserTable_row.maintableRow.LiTable_active > td {
    /* background: var(--claret-violet)!important; */
    background: var(--tufts-blue) !important;
    border-bottom: 1px solid var(--lighttheme-black-variant5) !important;
    color: white !important;
}

/****************** Media Query for screen width less than 800px ***************************/

@media only screen and (max-width: 800px) {
    .LiTable {
        width: 100%;
        min-width: 320px;
    }
    .LiTable th {
        display: none;
        float: left;
    }
    .LiTable thead tr {
        height: 0 !important;
    }
    .LiTable td {
        display: block;
        margin-bottom: 10px !important;
    }
    .LiUserTable_header_label {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .LiTable th:first-child {
        display: block;
        height: 40px !important;
        background-color: inherit !important;
    }
    .LiTable td:before {
        width: 30%;
        content: attr(data-header-row);
        float: left;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
    }
    .LiUserTable_searchContainer {
        width: 98%;
        margin: 0 auto;
    }
    .LiUserTable_actionButton {
        width: inherit !important;
        height: 100% !important;
        margin-left: 5px !important;
    }
    .LiUserTable_rowColumn>div {
        width: 70%;
        max-width: 100%;
        max-height: 100%
    }
    .LiUserTable_sortShowHide {
        height: 30px;
    }
    .SortArrows {
        display: none;
    }
    tr.LiTableRowBackGround {
        border-bottom: none !important;
        height: 30px !important;
        width: 20% !important;
        float: left;
    }
    .LightTheme.LiTable>thead>tr:nth-child(n)>th:nth-child(1) {
        /* background-color: #F4F4F4 !important; */                       
        background-color: var(--lighttheme-black-variant3) !important;   /*new color code change*/

        height: 30px !important;
    }
    .DarkTheme.LiTable>thead>tr:nth-child(n)>th:nth-child(1) {
        background-color: inherit !important;
        height: 30px !important;
    }
    .DarkTheme.LiTable {
        border-collapse: collapse !important;
    }
}

/* #saveSortButton > div.LightTheme.LiLineEdit_main{
    display: inline;
}
#saveSortButton > div.DarkTheme.LiLineEdit_main{
    display: inline;

} */

.sortNameDiv {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
}

.comboLabel {
    /* width: 10%; */
    float: left;
    margin-top: 12px;
    margin-right: 26px !important;
}

.LiUserTable_rowColumn.numericCol {
    text-align: right !important;
}

.DarkTheme #sortListItems>ul {
    background-color: #30373d !important;
}

.LightTheme #sortListItems>ul {
    background-color: #D3D3D3 !important;
}

.saveSortTop {
    margin-bottom: 15px;
}

.DarkTheme.actionPopup {
    background-color: #30373d !important;
}

.LightTheme.actionPopup {
    background-color: #D3D3D3 !important;
}

.tableAttributeLabel>label, .tableAttributeLabel>span {
    float: right !important;
    margin-right: 10px;
}

tr.LightTheme.LiUserTable_row.disableRow {
    filter: opacity(0.5);
    /* pointer-events: none; */
}
tr.DarkTheme.LiUserTable_row.disableRow {
    filter: opacity(0.5);
    /* pointer-events: none; */
}
.rowTrigCssHeight {
    min-height: 30px !important;
}


tr.DarkTheme.LiUserTable_row:hover td{
    background-color: var(--darktheme-tufts-blue-variant2) !important;
}

tr.LightTheme.LiUserTable_row:hover td{
    background-color: var(--lighttheme-tufts-blue-variant1) !important;
}
.LiTableColSearchBox{
    background-color: transparent !important;
}


.MuiPopover-root {
    z-index: 2500 !important;
}

.pageSizeArea > label > span{
    text-transform: lowercase;
}