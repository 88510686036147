.LiContextMenu_Dropdown {
  position: relative;
  /* border: gold 1px solid; */
}

.LiContextMenu_DropdownMenu {
  position: absolute;
  top: 5px !important;
  left: 0;
  z-index: 1;
  background-color: gainsboro;
  border-radius: 5px;
  /* border: green 1px solid; */
}

.LiContextMenu_Container {
  /* transition: 580ms ease-out; */
  overflow: hidden;
  min-width: 250px;
  min-height: 50px;
  /* padding: 10px 10px; */

  border: var(--lighttheme-black-variant1) 1px solid;

  /* color: #30373D; */
  /* border: blue 1px solid; */
  border-radius: 5px;
  word-wrap: break-word;
  float: left;

  line-height: 20px;
}

.LiContextMenuLabel {
  padding: 0px !important;
  margin: 0px !important;
  font-weight: bold !important;
  padding-bottom: 6px !important;
  /* border: 1px solid red; */
  width: 100%;
}

.LiContextMenuGridContainer {
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  height: 40px;
  /* border: 1px solid red; */
  line-height: 3;
  cursor: pointer;
  padding-left: 10px;
}

.LiContextMenuGridContainerFull {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: 40px;
  /* border: 1px solid red; */
  line-height: 3;
  cursor: pointer;
  padding-left: 10px;
}

.LiContextMenuHeaderGrid {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: 30px;
  /* border: 1px solid red; */
  line-height: 3;
  padding-left: 10px;
}

.LiContextMenuGridContainerItem {
  /* padding-top: 5px; */
  vertical-align: center;
  /* text-align: -webkit-center !important; */
  /* line-height: 0; */
  /* border: 1px solid blue; */
  background: none !important;
  padding-bottom: 5px !important;
}

.LiContextMenu {
  padding-top: 5px !important;
  text-decoration: none !important;
  display: inline-block;
}

.LiContextMenu a {
  color: #1075b9;
  font-weight: bold;
}

.LiContextMenu a:link {
  text-decoration: none !important;
}

.LiContextMenu a:visited {
  text-decoration: none !important;
  color: #1075b9;
}

.LiContextMenu a:hover {
  text-decoration: none !important;
  color: #1075b9;
}

/* .LiContextMenu_Container::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 3%;
  margin-left: 5px;
  border-width: 9px;
  border-style: solid;
} */

.hrcolor {
  height: 0.5px;
  border: none;
}

.contextMenuIcon {
  width: 20px;
  height: 20px;
  border-style: none;
  vertical-align: middle !important;
  padding-left: 2px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}

.LightTheme.LiContextMenu_DropdownMenu {
  background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiContextMenu_Container {
  background-color: var(--lighttheme-black-variant5);
  /* box-shadow: 5px 10px 8px 4px var(--LightTheme_backgroundColorEnabled); */
}

/* .LightTheme.LiContextMenu_Container::after {
  border-color: transparent transparent var(--LightTheme_backgroundColorEnabled)
    transparent;
} */

.LightTheme.LiContextMenuComboStyle {
  width: 130px !important;
}

.LightTheme.liContextMenuImg {
  width: 20px !important;
  cursor: pointer;
  padding-top: 10px !important;
  /* border: 1px solid red; */
}

.LightTheme.LiContextMenuGridContainer:hover {
  background-color: var(--lighttheme-black-variant2);
  color: var(--white);
}

.LightTheme.LiContextMenuGridContainerFull:hover {
  background-color: var(--lighttheme-black-variant2);
  color: var(--white);
}

.LightTheme.hrcolor {
  background-color: var(--lighttheme-black-variant1);
  height: 0.5px;
}

.DarkTheme.LiContextMenuGridContainerFull:hover {
  background-color: var(--darktheme-black-variant1);
  color: var(--white);
}

.DarkTheme.LiContextMenuGridContainer:hover {
  background-color: var(--darktheme-black-variant1);
  color: var(--white);
}

.DarkTheme.hrcolor {
  background-color: var(--darktheme-black-variant1);
  height: 0.5px;
}

.DarkTheme.LiContextMenu_DropdownMenu {
  background-color: var(--darktheme-black-variant3);
}

.DarkTheme.LiContextMenu_Container {
  background-color: var(--darktheme-black-variant3);
}

/* .DarkTheme.LiContextMenu_Container::after {
  border-color: transparent transparent var(--DarkTheme_backgroundColorEnabled)
    transparent;
} */

.DarkTheme.LiContextMenuComboStyle {
  width: 145px !important;
}

.DarkTheme.liContextMenuImg {
  width: 20px !important;
  cursor: pointer;
  padding-top: 10px !important;
}

.contextMenuWithChildren {
  display: block !important;
  width: 100% !important;
  cursor: pointer;
}
