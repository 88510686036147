.LiSliderInput {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  outline: none;
}

/*
.LightTheme.LiSliderInput {
  background: #b6b6b6;
}

.DarkTheme.LiSliderInput {
  background: #b6b6b6;
}
*/


.LiSliderInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;

  /* border: 1px solid red; */
}

.LightTheme.LiSliderInput::-webkit-slider-thumb {
  background: var(--tufts-blue);
}

.DarkTheme.LiSliderInput::-webkit-slider-thumb {
  background: var(--darktheme-tufts-blue);
}


.LiSliderInput::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;

  /* border: 1px solid green; */
}

.LightTheme.LiSliderInput::-moz-range-thumb {
  background: var(--tufts-blue);
}

.DarkTheme.LiSliderInput::-moz-range-thumb {
  background: var(--darktheme-tufts-blue);
}



/*for demo only*/
.vwv {
  /* margin: 0px 0px 0px 140px; */
  margin-left: 0;
}

.vwov {
  /* margin: 28px 0px 0px 200px; */
  margin-left: 300px;
}
/*for demo only*/

