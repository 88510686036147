.LiGridListTitle{
  font-weight: bold;
  width: 100% !important;
}

.LiGridList_HeaderBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DarkTheme.LiSelectListTitleButton {
  color: var(--white);
  float:right;
  margin-right: 5px;
}

.LightTheme.LiSelectListTitleButton {
  color: var(--lighttheme-black-variant1);
  float:right;
  margin-right: 5px;
}
.LigridList_Filter{
  margin-left:5% !important;
  margin-top:2% !important;
  height: 25px;
  width: 25px;
}
.LiSearchMainContainer{
  flex-direction: row;
  display:flex;
  width:100%;
  height:50%;
}
.LiSearchBarDiv{
  width: 80%;
}
.Ligridlist_CheckAllDiv
{
  text-align: left;
  margin-top: 2% !important;
  width:30px;
  margin-left: 15px !important;
}
.Ligridlist_StripContainer_Internal{
  /* margin-right:40px; */
  width: 25% !important;
}
.ExpansionContainer{
  width: 100% !important;
}
.Ligridlist_checkboxdiv{
  margin-left:10px;
}
.Ligridlist_StripExpansionpanel{
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
.DarkTheme.Ligridlist_StripContainer{
  width: 50%;
  margin-left: 45px;
  color: #ffffff;
  
}
.LightTheme.Ligridlist_StripContainer{
  width: 50%;
  margin-left: 45px;
}
.DarkTheme.Rowtext{
  width:41% !important;
  color: #2c3338;
  /* padding-top: 10px; */
}
.LightTheme.Rowtext{
  width:41% !important;
  color: #2c3338;
}
.Ligridlist_SearchBoxPane{
  width: 100% !important;
  height: 80px !important;
  top: 0px !important;
  position: relative !important;
  text-align: center !important;
  padding-top: 2.5%;
  padding-bottom: 1.5%;
}

.Ligridlist_Row{
  display: flex;
}
.Ligridlist_Rowdata_Child{
  display: flex;
  /* justify-content: center; */
  height: 22px !important;
  margin-bottom: 5px;
  padding-left: 18px;
  /* margin-left: 10px; */
}
.Ligridlist_Rowdata{
  width: 100%;
}
.Ligridlist_Rowdata_ExpansionContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.DarkTheme.Ligridlist_ColumHeader{
  width: 100%;
  height: 40px;
  margin-bottom:8px !important;
  margin-left:200px;

}

.Ligridlist_Rowdiv{
  width: 67% !important;
  display: none;
}
.LightTheme.Ligridlist_ColumHeader{
  width: 100%;
  height: 40px;
  margin-bottom:8px !important;
  margin-left:200px;

}

/* add borders */
.DarkTheme.GridListMainDiv
{
  border: 1px solid var(--darktheme-black-variant1) ; 
  background-color: var(--darktheme-black-variant1);
  background-color:"red";
  display: none;
  height: 45px;
  color: var(--darktheme-black-variant1);

}
.LightTheme.GridListMainDiv
{
  border: 1px solid var(--lighttheme-black-variant5) ; 
  background-color:var(--lighttheme-black-variant5) ;
  display: none;
  height: 45px;
}
.LiselectList_SelectAll{
  margin-top: 10px !important;
}
.ClassForGridBox  {
  width:100% !important;
  margin-top:2% !important;
  margin-left:2% !important;
}
.DarkTheme.LiGridList_MainContainer{
  background-color: var(--darktheme-black-variant1) !important;
  width:100% !important;
  /* border:1 px solid white !important; */
}
.LightTheme.LiGridList_MainContainer{
  background-color:var(--lighttheme-black-variant5) !important;
  /* border:1 px solid white !important; */
}
.LiGridList_Heading{
  margin-top:-47px !important;
  margin-left:240px;
  width:60px;
}
.Ligridlist_RowHeaderEmpty{
  width:348px !important;
}
.Ligridlist_RowHeader{
  width:128px !important;
}
.Ligridlist_HeadingElement{
  margin-top:10px;
}
#LiSelectListOuterMainDiv_defaultSelect > div:nth-child(n) > div > div > div > button > div > div > label > span{
  top: 8px !important;
  margin-left:7px !important;
}


.gridLink{
  color: #1075b9;
  cursor: pointer;
}
