.LiFilterPanel_Dropdown {
    position: relative;
    /* border: gold 1px solid; */
}

.LiFilterPanel_DropdownMenu {
    position: absolute;
    top: 10px !important;
    left: 0;
    z-index: 1;
    background-color:#D3D3D3;
    border-radius: 5px;
    /* border: green 1px solid; */
}

.LiFilterPanel_Container {
    /* transition: 580ms ease-out; */
    overflow: unset;
    height: 471px;
    width: 400px;
    padding: 10px 10px;
    
    /* color: #30373D; */
    /* border: blue 1px solid; */
    border-radius: 5px;
    word-wrap: break-word;
    float: left;
    
    line-height: 20px;
}

.LiFilterPanelLable{
    padding: 0px !important;
    margin:0px !important;
    font-weight: bold !important;
    padding-bottom: 6px !important;
}

.LiFilterPanelGridContainer {
    display: grid;
    grid-template-columns: 30% 21% 31% 18%;
    width: 100%;
    /* border: 1px solid red; */
}

.LiFilterPanelGridContainer1 {
    display: grid;
    grid-template-columns: 35% 65%;
    width: 100%;
    /* border: 1px solid red; */
}

.LiFilterPanelGridContainer2 {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.LiFilterPanelGridContainer3 {
    display: grid;
    grid-template-columns: 26% 30% 44%;
    width: 100%;
    /* border: 1px solid red; */
}


.LiFilterPanelGridContainerItem {
    /* padding-top: 5px; */
    vertical-align: top;
    /* text-align: -webkit-center !important; */
    /* line-height: 0; */
    /* border: 1px solid blue; */
    background: none !important;
    padding-bottom: 5px !important;

}

.LiFilterPanel{
    padding-top: 5px !important;
    text-decoration: none !important;
    display: inline-block;
}

.LiFilterPanel a {
    color: #1075b9;
    /* font-we ight: bold; */
}
  

.LiFilterPanel a:link{
    text-decoration: none !important;
}
  
  
.LiFilterPanel a:visited{
    text-decoration: none !important;
    color: #1075b9;
}
  
.LiFilterPanel a:hover{
    text-decoration: none !important;
    color: #1075b9;
}

.LiFilterPanel_Container::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 3%;
    margin-left: 5px;
    border-width: 9px;
    border-style: solid;
}

.LightTheme.LiFilterPanel_Container {
    background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiFilterPanel_Container::after {
    border-color: transparent transparent var(--lighttheme-black-variant5) transparent
}

.LightTheme.LiFilterPanelComboStyle
{
    width: 110px !important;
}

.LightTheme.hrcolor{
    background-color: var(--lighttheme-black-variant1);
    height: 0.5px;
}



.DarkTheme.LiFilterPanel_DropdownMenu {
    background-color: var(--darktheme-black-variant3);
}

.DarkTheme.LiFilterPanel_Container {
    background-color: var(--darktheme-black-variant3) ;
    color: var(--white);
    /* box-shadow: 5px 10px 8px 4px #D3D3D3; */
}

.DarkTheme.LiFilterPanel_Container::after {
    border-color: transparent transparent var(--darktheme-black-variant3) transparent
}

.DarkTheme.LiFilterPanelComboStyle
{
    width: 110px !important;
}

.DarkTheme.hrcolor{
    background-color: var(--darktheme-white-variant1);
    height: 0.5px;
}


 