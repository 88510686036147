.LiSelectList_SearchBox {
  width:83% !important;
  height: 30px !important;

  position: absolute !important;

  margin-left: 50px !important;
  top: 50px !important;
  padding: 0px !important;
}

.LiSelectListTitle {
  font-weight: bold;
}

.LightTheme.LiSelectListTitle {
  color: var(--lighttheme-black-variant1);
}

.DarkTheme.LiSelectListTitleButton {
  color: var(--white);
  float:right;
  margin-right: 5px;
}

.LightTheme.LiSelectListTitleButton {
  color: var(--lighttheme-black-variant1);
  float:right;
  margin-right: 5px;
}

.DarkTheme.LiSelectListTitle {
  color: var(--white);
}

.LiSelectList_HeaderArea {
  position: relative;
}

.LiSelectList_FilterIcon {
  margin-right: 15px !important;
  float: right !important;
}

.LiSelectList_ListArea {
  position: relative;
  width: 100% !important;
  height: 300px !important
}

.LiSelectList_CheckAllDiv {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  text-align: left;
}

.LiSelectList_CheckAllInput {
  margin-left: 20px !important;
  cursor: pointer;
}


/* .LiSelectListCheckAll {
  height: 30px !important;

  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
  margin-bottom: 2px !important;
} */



.LiSelectListOuterMainDiv {
  width: 100% !important;
  height: 100%;

  overflow: auto;
}

.LiSelectListInnerSpan {
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
  margin-bottom: 2px !important;
  height: 30px !important;
  /* border: 1px solid rebeccapurple; */
}

.LiSelectList_SearchBoxPane {
  width: 100% !important;
  height: 80px !important;
  top: 0px !important;
  position: relative !important;

  text-align: center !important;
  padding-top: 10px;
}

.DarkTheme.LiSelectListOuterMainDiv {
  background-color: var(--darktheme-black-variant1);
}

.DarkTheme.LiSelectListInnerSpan {
  color: var(--white);
}

.DarkTheme.LiSelectListInnerSpan:hover {
  background-color: var(--darktheme-tufts-blue-variant1) ;
  color: var(--white);
}

.DarkTheme.LiSelectListInnerSpan:checked {
  color: black;
}

.DarkTheme.LiSelectList_checkedClass {
  background-color: var(--darktheme-tufts-blue) ;
  color: var(--white);
}

.DarkTheme.LiSelectList_SearchBoxPane {
  background-color: var(--darktheme-black-variant1);
}

.LightTheme.LiSelectListOuterMainDiv {
  background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiSelectListInnerSpan {
  color: var(--lighttheme-white-variant1);
}

.LightTheme.LiSelectListInnerSpan:hover {
  background-color: var(--lighttheme-tufts-blue-variant1) !important;
  /* color: var(--black); */
}

.LightTheme.LiSelectList_checkedClass {
  background-color: var(--tufts-blue) ;
  color: var(--white);
}

.LightTheme.LiSelectList_SearchBoxPane {
  background-color: var(--lighttheme-black-variant5);
}

.ListSelectList_MainGridContainer {
  display: grid;
  grid-template-columns: 10% 45% 45%;
  width: 99%;
}

.ListSelectList_MainGridContainerItem {
  vertical-align: top;
  text-align: left;
  background: none !important; 
}
