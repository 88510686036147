
.LiTimepicker_maincontainer {
  line-height: 2;
}

/* .LiTimePicker_FormGroup {
  display: flex;
  flex-direction: row-reverse;
} */

.LiTimePicker_FormGroup_Higlight {
  display: flex;
  flex-direction: row-reverse;
  border: 0.75px solid var(--tufts-blue) !important;
}

.cursorAllowed {
  /* border: 1px solid darkgreen; */
}

.cursorNotAllowed {
  cursor: not-allowed;
  /* border: 1px solid green; */
}

.cursorPointer {
  pointer-events: none;
  opacity: 0.4;
}

.LiTimePicker_InputIcon {
  position: absolute;
  width: 0px;
  height: 0px;
  
  padding: 12px 12px 12px 12px;
  border-style: none;
  
  top: 8px;
  right: 0px;
  
  cursor: pointer;
  pointer-events: auto;
}

.LightTheme.LiTimePicker_InputIcon {
  background: url("../../images/litimepicker/clock_active_light_theme.svg") no-repeat;
}

.DarkTheme.LiTimePicker_InputIcon {
  background: url("../../images/litimepicker/clock_active_dark_theme.svg") no-repeat;
}

.LiTimePicker_InputIconDisabled {
  position: absolute;
  width: 0px;
  height: 0px;

  padding: 12px 12px 12px 12px;
  border-style: none;

  top: 8px;
  right: 0px;

  cursor: not-allowed;
  pointer-events: none;
}

.LightTheme.LiTimePicker_InputIconDisabled {
  background: url("../../images/litimepicker/light_theme_disabled.svg") no-repeat;
}

.DarkTheme.LiTimePicker_InputIconDisabled {
  background: url("../../images/litimepicker/dark_theme_disabled.svg") no-repeat;
}

.LightTheme.LiTimePicker_Input_Highlight {
  color: var(--black);
  background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiTimePicker_Input_Highlight {
  color: var(--darktheme-tufts-blue);
  background-color: var(--darktheme-black-variant3);
}

.cursorNotAllowed{	
  width: 105px;	
}	

.cursorAllowed{	
  width: 105px;	
}


.LiTimePicker_Input {
  width: 105px !important;
  height: 30px;

  border: 0.75px solid transparent;
  font-size: 13px;

  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 1px;
  outline: none;
  text-align: left;
}

.LiTimePicker_Input_Highlight {
  width: 105px !important;
  height: 30px;

  border: 0.75px solid transparent;

  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  
  letter-spacing: 1px;
  outline: none;
  text-align: left;
}

.LightTheme.LiTimePicker_Input {
  color: var(--black );
  background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiTimePicker_Input {
  color: var(--white);
  background-color: var(--darktheme-black-variant3);
} 

.LightTheme.LiTimePicker_Input_Highlight {
  color: var(--black );
  background-color: var(--lighttheme-black-variant5);
  border-color: var(--tufts-blue);
}

.DarkTheme.LiTimePicker_Input_Highlight {
  color: var(--white);
  background-color: var(--darktheme-black-variant3);
  border-color: var(--darktheme-tufts-blue);
}


.LightTheme.LiTimePicker_InputIcon:hover + .LightTheme.LiTimePicker_Input {
  border: 0.75px solid var(--tufts-blue) !important;
}

.DarkTheme.LiTimePicker_InputIcon:hover + .DarkTheme.LiTimePicker_Input {
  border: 0.75px solid var(--darktheme-tufts-blue ) !important;
}


.LightTheme.LiTimePicker_Input {
  color: var(--black);
  background-color: var(--lighttheme-black-variant5);
  border: 0.75px solid var(--lighttheme-black-variant1);
}

.DarkTheme.LiTimePicker_Input {
  color: var(--white);
  background-color: var(--darktheme-black-variant3);
  border: 0.75px solid var(--darktheme-white-variant1);
}

.LightTheme.LiTimePicker_Input:hover {
  border: 0.75px solid var(--black);
}

.LightTheme.LiTimePicker_Input:focus {
  border: 0.75px solid var(--tufts-blue);
}

.LightTheme.LiTimePicker_Input:disabled {
  border: 0.75px solid var(--lighttheme-black-variant2);
  opacity: 0.4;
}

.LightTheme.LiTimePicker_Input:disabled:hover {
  border: 0.75px solid var(--lighttheme-black-variant2);
  cursor: not-allowed;
}

.LightTheme.LiTimePicker_Input::placeholder {
  color: var(--lighttheme-black-variant1);
  font-size: 13px;
}

.LightTheme.LiTimePicker_Input_Highlight::placeholder {
  color: var(--black);
  font-size: 13px;
}

.DarkTheme.LiTimePicker_Input:hover {
  border: 0.75px solid var(--white) !important;
}

.DarkTheme.LiTimePicker_Input:focus {
  border: 0.75px solid var(--darktheme-tufts-blue ) !important;
}

.DarkTheme.LiTimePicker_Input_Highlight::placeholder {
  color: var(--white);
  font-size: 13px;
}

.DarkTheme.LiTimePicker_Input:disabled {
  border: 0.75px solid var(--DarkTheme_backgroundColorDisabled1);
  opacity: 0.4;
}

.DarkTheme.LiTimePicker_Input:disabled:hover {
  border: 0.75px solid var(--DarkTheme_backgroundColorDisabled1);
  cursor: not-allowed;
}





.LiTimePicker_Container {
  display: inline-block;
  position: relative;
  /* border: 1px solid red; */
}

.LiTimePicker_Dropdown {
  position: relative;
}

.LiTimePicker_DropdownMenu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}



/* 
.LiTimePicker_maintime{
  display: flex; 
  flex-direction: row;

  top: 69px;
  left: 235px;

  width: 285px;
  height: 40px;
  opacity: 1;
  -webkit-user-select: none;

  border: 1px solid blue;
}

.LightTheme.LiTimePicker_maintime{
  border: 0px solid  var(--LightTheme_borderColorSelected) !important;
  background-color: var(--LightTheme_backgroundColorEnabled);
}
.DarkTheme.LiTimePicker_maintime{
  border: 0px solid var(--DarkTheme_borderColorSelected) !important;
  background-color: var(--DarkTheme_backgroundColorEnabled);
}
.LightTheme.LiTimePicker_maintime:focus{
  border: 0.75px solid var(--lightTheme-hoverColor) !important;
  opacity: 1;
  height: 500px;
}
.DarkTheme.LiTimePicker_maintime:focus{
  border:1px solid var(--darkTheme-hoverColor) !important;
  opacity: 1;
}
.LightTheme.LiTimePicker_maintime:hover{
  border: 0.75px solid var(--lightTheme-hoverColor) !important;
  opacity: 1;
}
.DarkTheme.LiTimePicker_maintime:hover{
  border: 1px solid var(--darkTheme-hoverColor) !important;
  opacity: 1;
} */



/* .LiTimePicker_maindiv{
  height: 120%;
  min-height: 120%;
  margin-left:-160px;
  display: flex;
  justify-content: space-around;

  border: 1px solid yellow;
}

.DarkTheme.LiTimePicker_maindiv{
  background-color: var(--lightTheme-color)  !important;
} */

.LiTimePicker_mainlist {
  width: 230px;
  height: 260px;
  
  position: fixed;
  flex-direction: row;  
  justify-content: center;
}

.LiTimePicker_mainlist_WithOutCombo{
  width: 230px;
  height: 220px;
  
  position: fixed;
  flex-direction: row;  
  justify-content: center;
}

.LightTheme.LiTimePicker_mainlist{
  background-color: var(--lighttheme-black-variant5);
  
  border: 1px solid var(--lighttheme-black-variant2);
}

.DarkTheme.LiTimePicker_mainlist{
  background-color: var(--darktheme-black-variant3);

  border: 1px solid var(--darkTheme-borderColor);
}

.DarkTheme.LiTimePicker_mainlist_WithOutCombo{
  background-color: var(--darktheme-black-variant3);

  border: 1px solid var(--darkTheme-borderColor);
}

.LightTheme.LiTimePicker_mainlist_WithOutCombo{
  background-color: var(--lighttheme-black-variant5);
  
  border: 1px solid var(--lighttheme-black-variant2);
}

.LiTimePicker_timezonacontainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px !important;
  margin-top: 70px;
}

.DarkTheme.LiTimePicker_timezonacontainer{
  background-color: var(--darktheme-black-variant3);
}

.LightTheme.LiTimePicker_timezonacontainer{
  background-color: var(--lighttheme-black-variant5);
}

.LiTimePicker_timezonecomobox{
  width: 100%;
  margin: 10px;
}

.LiTimePicker_clockicon{
  background-size: cover;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  /* margin-left: 80px; */
}

.LightTheme.LiTimePicker_clockicon{
  background-image: url("../../images/litimepicker/clock_active_light_theme.svg");
  margin-top: 10px;
}

.DarkTheme.LiTimePicker_clockicon{   
  background-image: url("../../images/litimepicker/clock_active_dark_theme.svg");
  margin-top: 8px;
}

.LightTheme.LiTimePicker_uparrowdiv{
  cursor: pointer;
  -webkit-user-select: none;
}

.LightTheme.LiTimePicker_downarrowdiv{
  cursor: pointer;
  -webkit-user-select: none;
}

.LiTimePicker_timetext{
  /* width: 150px; */
  
  font-size: 18px;
  letter-spacing: 0;

  padding-left: 10px !important;
  padding-right: 10px !important;
  
  flex-direction: row !important;
  display: flex !important;
  -webkit-user-select: none !important;
}

.LightTheme.LiTimePicker_timetext{
  color: var(--black);
}

.DarkTheme.LiTimePicker_timetext{
  color: var(--white);
}

.LiTimePicker_uparrow{
  width: 20px;
  height: 20px;
  margin-top: 5px !important;
  margin-left: 10px;
  background-image: url("../../images/litimepicker/up_arrow_active.svg");
  background-size: cover;
  cursor: pointer;
}

.LiTimePicker_downarrow{
  width: 20px;
  height: 20px;
  /* padding-bottom: 35px !important; */
  margin-left: 10px !important;
  margin-top: 60px;
  background-image: url("../../images/litimepicker/down_arrow_active.svg");
  background-size: cover;
  cursor: pointer;
}

.LiTimePicker_rowdiv1{
  opacity: 0.7;
  cursor: pointer;
  -webkit-user-select: none !important;
  padding: 10px;
  font-size: 18px;
  font-family: 'Roboto-Light', Arial, sans-serif !important; 
  margin-bottom: 7px;
  margin-top: -7px;
}

.DarkTheme.LiTimePicker_rowdiv1{
  color: var(--white);
}

.LiTimePicker_rowdiv2{
  opacity: 0.7;
  cursor: pointer;
  -webkit-user-select: none !important;
  padding: 10px;
  font-size: 18px;
  font-family: 'Roboto-Light', Arial, sans-serif !important; 
  margin-bottom: 5px;
  margin-top: 3px;
}

.DarkTheme.LiTimePicker_rowdiv2{
  color: var(--white);
}

.LiTimePicker_centerdiv {
  width: 25px;
  
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  margin-left: 8px;
  
  margin-top: -1px;
  font-family: 'Roboto-Light', Arial, sans-serif !important; 
  padding: 1px;
}

.LightTheme.LiTimePicker_centerdiv{
  border-top: solid 1.5px var(--tufts-blue ); 
  border-bottom: solid 1.5px var(--tufts-blue ); 
  color: var(--tufts-blue );
}

.DarkTheme.LiTimePicker_centerdiv{
  border-top: solid 1.5px var(--darktheme-tufts-blue);
  border-bottom: solid 1.5px  var(--darktheme-tufts-blue);
  color: var(--darktheme-tufts-blue);
}


.LiTimePicker_uparrowdiv{
  cursor: pointer;
  -webkit-user-select: none;
}

.LiTimePicker_hour_12{
  margin-right: 4px;
}
.LiTimePicker_minutes_12{
  margin-right: 4px;
}
.LiTimePicker_mode_12{
  margin-left: 5px;
}
.LiTimePicker_minutes_24{
  margin-left: 5px;
}
.LiTimePicker_hour_24{
  margin-right: 4px;
}
.LiTimePicker_example_24hour{
  margin-left: 400px;
  margin-top:-86px
}
.LiTimePicker_inactiveexample{  
  margin-left:800px;
  margin-top:-90px;
}

.LiTimePicker_titletext{
  margin-left: 50px;  
}

.DarkTheme.LiTimePicker_input_hour_12{
  margin-right: 4px;
  background-color: var(--darktheme-black-variant3);
  color: var(--white);
  border-width: 0;
}

.DarkTheme.LiTimePicker_input_minutes_12{
  margin-right: 4px;
  background-color: var(--darktheme-black-variant3);
  color: var(--white);
  border-width: 0;
}

.DarkTheme.LiTimePicker_input_mode_12{
  margin-left: 5px;
  background-color: var(--darktheme-black-variant3);
  color: var(--white);
  border-width: 0;
}

.LiTimePicker_NowBtn {
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  user-select: none;
  padding: 4px 7px;
  height: 20px;
  line-height: 20px;
  width: 40px;
  margin-top: 15px;
}

.LightTheme.LiTimePicker_NowBtn {
  border: 0.50px solid var(--black);
  color: var(--lighttheme-black-variant1);
}

.DarkTheme.LiTimePicker_NowBtn {
  border: 0.50px solid  var(--white);
  color: var(--white);
}

.LiTimePicker_picker2{
  margin-left: -250px;
}

.LiTimePicker_Title{
  margin-left: 1px;
  font-size: 15px;
  font-family: 'Roboto-Light', Arial, sans-serif !important; 
}

.LiTimePicker_dropDownContainer{
  width: 35px !important;
  padding: 2px !important;
  margin-right: 20px !important;
}

.LiTimePicker_innerDropdown{
  height: 100px !important;
}

.LiTimePicker_dropdownBody{
  position: relative !important;
}