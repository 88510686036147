.statusdialogcontainer{
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    /* border: 1px solid red; */
}

/* .listatus_dialog{
    height: 7vh !important;
    background-color: lime;

    border: 1px solid red;
} */