.LiCombo_main {
    /* margin-left: 5px; */
    text-align: justify;
}

.LiCombo_overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */    
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.5s;
}

.LiCombo_container{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: inherit;
    height: 28px;
    margin-top: 5px;
    cursor: pointer;
    line-height: 2;
}

.LiComboBox_disabled .LiCombo_selected {
    border: none;
    cursor: not-allowed;
}

.LiComboList {
    z-index: 1010;
    margin: 0;
    white-space: nowrap;
    padding: 0 10px;
}

.LiComboList_span.LiComboList_multiSelectSpan {
    padding-left: 25px;
}

.LiComboList > *{
    /* pointer-events: none; */
}

.LiComboList_container {
    display: inline-block;
    width: 100%;
    height: 34px;
    /* float: left; */
}

.LightTheme .LiComboList_container {
    color: var(--black);
}

.DarkTheme .LiComboList_container {
    color: var(--white);
}

input.LiComboList_search {
    width: 98%;
    font-size: 16px;
    height: 25px;
}

label.LiCombo_floatinglabel {
    font-size: 13px;
}

.LiCombo_containerInputLabel{
    position: absolute;
    left: 10px;
    /* top: 0px; */
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);

}

.LiCombo_selected {
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    position: relative;
}

.LiCombo_selected img {
    width: 6%;
    float: left;
}

.LiComboList_multiSelect {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
    pointer-events: none;
  }
  
  /* Hide the browser's default checkbox */
  .LiComboList_multiSelect input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .LiComboList_checkmark {
    position: absolute;
    top: 7px;
    left: 10px;
    height: 15px;
    width: 15px;
    /* border: 1px solid var(--black); */
    background-color: var(--white);
  }

  .DarkTheme .LiComboList_checkmark{
    border: 1px solid var(--white);
    background-color: var(--darktheme-black-variant3);
  }

  .LightTheme .LiComboList_checkmark{
    border: 1px solid var(--black);
    background-color: var(--lighttheme-black-variant5);
  }
  
  /* On mouse-over, add a grey background color */
  .LiComboList_multiSelect:hover input ~ .LiComboList_checkmark {
    background-color: var(--lighttheme-black-variant5);
  }
  
  /* When the checkbox is checked, add a blue background */
  .LightTheme .LiComboList_multiSelect input:checked ~ .LiComboList_checkmark {
    border: none;
    background-color: var(--white);
  }

  .DarkTheme .LiComboList_multiSelect input:checked ~ .LiComboList_checkmark {
    border: none;
    background-color: var(--white);
  }
  
  /* Create the LiComboList_checkmark/indicator (hidden when not checked) */
  .LiComboList_checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the LiComboList_checkmark when checked */
  .LiComboList_multiSelect input:checked ~ .LiComboList_checkmark:after {
    display: block;
  }
  
  /* Style the LiComboList_checkmark/indicator */
  .LiComboList_multiSelect .LiComboList_checkmark:after {
    left: 5px;
    top: 0px;
    width: 3px;
    height: 10px;
    background: rgba(0, 0, 0, 0);
    border: solid var(--tufts-blue);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .LightTheme .LiCombo_selected:before {
    position: absolute;
    content: url('../../images/licombobox/light_down_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 5px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.LightTheme .LiCombo_selected.LiCombo_close:before {
    position: absolute;
    content: url('../../images/licombobox/light_up_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 5px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.DarkTheme .LiCombo_selected:before {
    position: absolute;
    content: url('../../images/licombobox/dark_down_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 5px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.DarkTheme .LiCombo_selected.LiCombo_close:before {
    position: absolute;
    content: url('../../images/licombobox/dark_up_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 5px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.dark.LiCombo_selected:before {
    position: absolute;
    content: url('../../images/licombobox/dark_down_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 5px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.dark.LiCombo_selected.LiCombo_close:before {
    position: absolute;
    content: url('../../images/licombobox/dark_up_arrow.svg');
    /* background-image: url('../../images/licombobox/arrow_1.svg'); */
    top: 2px;
    right: 7px;
    height: 7px;
    width: 17px;
}

.LiCombo_options {
    max-height: 300px;
    overflow-y: auto;
}

.LiCombo_options::-webkit-scrollbar {
    width: 3px;
    /* background-color: #881B4c; */
}

/* .LightTheme .LiCombo_container:hover{
    border: 0.5px solid var(--tufts-blue) !important;
} */

.LightTheme .LiCombo_selectedCont:hover{
    /* border: 1px solid var(--black) !important; */
    border: 1px solid var(--lighttheme-black-variant1);

}

.LightTheme .LiCombo_selectedCont.LiCombo_close {
    border: 1px solid var(--tufts-blue);
}

.LightTheme .LiCombo_selected{
    color: var(--black) !important;
    background-color:  var(--lighttheme-black-variant5) !important;
    border: 1px solid var(--lighttheme-black-variant1);
}

.LightTheme .LiComboList:hover {
    background-color: var(--lighttheme-tufts-blue-variant1) !important;
    /* border: 1px solid var(--tufts-blue) !important; */
}


.LiCombo_optionlist {
    background-color: var(--lighttheme-black-variant5) !important;
    width: 100%;
    font-size: 16px;
    position: fixed;
    z-index: 1010;
    width: inherit;
    border: 1px solid transparent;
    border-top: 0;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    margin-top: 0px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}

.LightTheme input.LiComboList_search:focus {
    outline: var(--black);
}

input.LiCombo_selected{
    width: 80%;
    height: 86%;
    margin-top: 2px;
    /* float: left; */
    /* margin-left: 10px; */
    box-sizing: border-box;
    font-size: 16px;
    pointer-events: none;
}


.LightTheme input.LiCombo_selected {
    background-color: var(--lighttheme-black-variant5);
    border: none;
    
}

/* .LightTheme .LiCombo_selected:hover {
    display: inline-block;
    width: 100%;
    height: inherit;
} */

.DarkTheme .LiCombo_optionlist {
    background-color: var(--darktheme-black-variant3) !important;
    border: 1px solid var(--darktheme-white-variant1);
}

.LightTheme .LiCombo_optionlist {
    border: 1px solid var(--lighttheme-black-variant1);
}

/* .DarkTheme .LiCombo_container:hover{
    border: 1px solid #4080C1 !important;
} */

.DarkTheme .LiCombo_selected:hover {
    border: 1px solid var(--white) !important;
}

.DarkTheme .LiCombo_selected{
    color: var(--white) !important;
    background-color: var(--darktheme-black-variant3) !important;
    border: 1px solid var(--darktheme-white-variant1);
}

.DarkTheme .LiComboList:hover {
    background-color: var(--darktheme-yale-blue) !important;
    /* border: 1px solid var(--tufts-blue) !important; */
}

div.LiComboList_search {
    padding: 0;
    margin: 0;
    width: 99%;
}

.DarkTheme input.LiComboList_search:focus {
    outline: var(--darktheme-tufts-blue);
}

.DarkTheme input.LiCombo_selected {
    background-color: var(--darktheme-tufts-blue);
    border: none;
}

.DarkTheme .LiCombo_selected:hover {
    display: inline-block;
    width: 100%;
    height: inherit;
}

.AdvanceComboButtons{
    margin-left: 10px;
    cursor: auto;
}

.AdvanceComboButtons span {
    font-size: 14px;
}

/* .AdvanceComboButtons .closeLabel {
    margin-left: 5px;
}

.AdvanceComboButtons .buttonsContainer{
    margin-left: -5px;
    margin-bottom: 10px;
} */

.LiComboListHeader{
    display: flex;
    font-size : 16px;
    font-weight : 600;
    padding-left: 10px; 
    padding-top: 5px; 
    margin-top : 5px; 
    cursor : auto;
}

.LightTheme .LiComboListHeader {
    border-top : 0.25px solid var(--black); 
}

.DarkTheme .LiComboListHeader {
    border-top : 0.25px solid var(--white); 
}

.LiComboListHeaderImage{
    height: 20px;
    margin-top: 5px;
}

.LiComboListHeaderText{
    margin-left: 5px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Webkit-specific CSS here (Chrome and Safari) */
  
    .LiCombo_optionlist {
      /* styles here, background image etc */
      position: fixed;
    }
  }