.LiTextArea {
  /* width: 297px; */
  width: 97%;
  height: 40px;

  /* top: 65px;
  left: 482px; */

  outline: none;
  padding: 2px 2px;

  min-width: 60px;
  min-height: 40px;

  opacity: 1;
}

.LiTextArea:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.LightTheme.LiTextArea {
  border: 0.75px solid var(--lighttheme-black-variant1);
  background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiTextArea:hover {
  border: 0.75px solid var(--black);
}

.LightTheme.LiTextArea:disabled {
  border: 0.75px solid var(--lighttheme-black-variant2);
  background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiTextArea:focus {
  border: 0.75px solid var(--tufts-blue);
  background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiTextArea {
  border: 0.75px solid var(--darktheme-white-variant1);
  background-color: var(--darktheme-black-variant3);
  color: white;
}

.DarkTheme.LiTextArea:hover {
  border: 0.75px solid var(--white);
}

.DarkTheme.LiTextArea:disabled {
  border: 0.75px solid var(--darktheme-white-variant3);
  background-color: var(--darktheme-black-variant3);
}

.DarkTheme.LiTextArea:focus {
  border: 0.75px solid var(--darktheme-tufts-blue);
  background-color: var(--darktheme-black-variant3);
}
