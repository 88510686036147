.imageButtonDefault { 
  position: absolute;
  opacity: 0;
  width: 26px !important;
  height: 26px !important;
  cursor: pointer;
}

/* IMAGE STYLES */
.imageButtonDefault + img {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  padding: 6px !important;
  /* outline: 1px solid #CACBCA; */
}

/* CHECKED STYLES */
.imageButtonDefault:checked + img {
  /* outline: 2px solid #f00; */
  background-color: var(--lighttheme-black-variant2) !important;
}

/* CHECKED STYLES */
.DarkTheme .imageButtonDefault:checked + img {
  /* outline: 2px solid #f00; */
  background-color: var(--lighttheme-black-variant2) !important;
}

.imageButtonLabel{
  /* padding-right: 10px; */
}

.imageButtonAlignStyle{
  display: flow-root !important;
}
