/* .xty {
  overflow: hidden;
  position: relative;
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  height: 70px;
  background: lightblue;
  text-align: center;
} */

.LiUserTable_rowColumn  {
  /* overflow: unset !important;  */
}

.has-liTooltip2 {
  position: relative !important;
  display: inline;  
  /* border: 1px solid var(--darkTheme-borderColor); */
}

.liTooltip2-wrapper {
  position: absolute !important;
  visibility: hidden;
  border-radius: 3px;
  /* height: 1px !important; */
  border: 1px solid var(--lightTheme-borderColor);
  /* left: -100px; */
}

.DarkTheme.liTooltip2-wrapper {
  border: 1px solid var(--darkTheme-borderColor);
}

.has-liTooltip2:hover .liTooltip2-wrapper {
  visibility: visible;
  z-index: 1;
  /* position: relative; */

  /* opacity: 0.7; */
  /* top: 30px;*/
  /* left: 50%;*/
  /* margin-left: -76px;*/
  /* z-index: 999; defined above with value of 5 */
}

.liTooltip2 {
  display: block;
  position: relative;
  /* top: 2px; */
  /* right: 100%; */
  /* left: -210px; */
  /* width: 300px; */
  
  /* margin-left: -76px;*/
  white-space: nowrap;
  color: #000000;
  background-color: #fff;
  

  /* height: 30px; */
  line-height: 30px;
  text-align: left;
  border-radius: 2px;
  padding-left: 2px;
  padding-right: 2px;
  /* box-shadow: 4px 3px 10px #800000; */
  
}

.liTooltipTextWrap{
  white-space:normal !important;
  width: 300px !important;
}

/* 
.liTooltip2:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-bottom: 8px solid #000000;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
} */

.liTooltip2-wrapper_Pos1 {
  left: 0 !important;
  top: 20px !important;
}

.liTooltip2-wrapper_Pos2 {
  right: 0px !important;
  top: 20px !important;
}

.liTooltip2-wrapper_Pos3 {
  /* top: -200px !important; */
}

.liTooltip2-no-wrapper {
  /* top: -200px !important; */
  right: 0px !important;
  top: 20px !important;
}
