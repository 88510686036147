.LightTheme.LiLabel_Basic {
    color: var(--black);
}

.DarkTheme.LiLabel_Basic {
    color: var(--white);
}

.mandatoryFieldNote {
    color: red;
    font-weight: bold;
}
