.LiLineEdit {
  display: inline-block;
  width: 97%;
  height: 30px;
  outline: none;
  border: 1px solid transparent;
  opacity: 1;
  padding-left: 6px !important;
}

.LiLineEdit_main {
  /* border: solid 1px #ccc; */
  /* padding: 0 8px; */
  position: relative;
}

.LiLineEdit_Error {
  position: inherit;
  text-align: initial;
  padding-top: 2px !important;
}

.LiLineEdit_label {
  display: inline-block;
  font-family: 'Roboto-Regular', Arial, sans-serif !important;
  font-size: 14px;
  color: red;
  padding-top: 0px !important;
}

.LiLineEditErrorIcon {
  padding-right: 3px;
  padding-top: 0px;
  width: 15px;
  height: 15px;
}

.LiLineEditFloatingLabel {
  position: absolute !important;
  pointer-events: none;
  transition: 0.2s ease all;
  user-select: none;
  cursor: text !important;
  color: #282C30 !important;
  opacity: 0.5;
  padding-left: 7px;
  top: 50%;
  transform: translateY(-50%);

}

.LightTheme.LiLineEdit {
  background-color: var(--lighttheme-black-variant5) !important;
  border: 1px solid var(--lighttheme-black-variant1);
  color: black !important;
}

.LightTheme.LiLineEdit:hover {
  border: 1px solid var(--black);
}

.LightTheme.LiLineEdit[type='text']:disabled {
  border: 1px solid var(--lighttheme-black-variant2);
  background-color: var(--lighttheme-black-variant5);
  opacity: 0.5;
  cursor: not-allowed;
}

.LightTheme.LiLineEdit[type='text']:focus, .LightTheme.LiLineEdit[type='password']:focus {
  top: 65px;
  left: 482px;
  /* width: 97%;
   height: 30px; */
  outline: none;
  border: 1px solid var(--tufts-blue);
  opacity: 1;
  background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiLineEdit.required {
  border: 1px solid red;
}

/***************************************************************************************************************/

.DarkTheme.LiLineEdit {
  background-color: var(--darktheme-black-variant3) !important;
  color: white !important;
  border: 1px solid var(--darktheme-white-variant1);
}

.DarkTheme.LiLineEdit:hover {
  border: 1px solid var(--white);
}

.DarkTheme.LiLineEdit[type='text']:disabled {
  border: 1px solid var(--darktheme-white-variant3);
  background-color: var(--darktheme-black-variant3);
  opacity: 0.5;
  cursor: not-allowed;
}

.DarkTheme.LiLineEdit[type='text']:focus, .DarkTheme.LiLineEdit[type='password']:focus {
  top: 65px;
  left: 482px;
  /* width: 97%;
  height: 30px; */
  outline: none;
  /* border: 1px solid #4080C1; */
  border: 1px solid var(--darktheme-tufts-blue);
  opacity: 1;
  background-color: var(--darktheme-black-variant5);
}

.DarkTheme.LiLineEdit.required {
  border: 1px solid red;
}

.DarkTheme.LiLineEditFloatingLabel {
  color: var(--white) !important;
}

input[readonly] {
  cursor: not-allowed;
}