.LiMainSpanClass{
  line-height: 2 !important;
  /* border: 1px solid red; */
  /* padding: 5px; */
}

.LiSwitch_LabelSpan{
  color: #3C454B !important;
  padding-left:20px;
  user-select: none;
}


.LiSwitch_LabelClass {
  position: relative;
  /* display: inline-block; */
  width: 32px;
  height: 14px;
  /* padding-left: 20px; */
}

.LiSwitch_LabelClass input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.LiSwitch_SliderClass {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--lighttheme-black-variant4);
  -webkit-transition: .4s;
  transition: .4s;
  line-height: initial !important;
}

.LiSwitch_SliderClass:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -8px;
  bottom: -4px;
  background-color: var(--white);
  -webkit-transition: .4s;
  transition: .4s;
  line-height: initial !important;
}

input:checked + .LiSwitch_SliderClass {
  background-color: var(--lighttheme-tufts-blue-variant1);
}

input:checked + .LiSwitch_SliderClass:before {
  background-color: var(--tufts-blue); 
  left: -6px;
}


/* .overStyle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #AEAEAF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
} */

/* .overStyle:before {
  position: absolute;
  content: "";
  height: 16.5px;
  width: 16.5px;
  left: -3px;
  bottom: -1px;
  background-color: #626566;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:hover + .overStyle:before {
  border-radius: 50%;
  transform: scale(2.6);
  background: #881B4C;
  opacity: 0.25;
  pointer-events: none;
} */


input:disabled + .LiSwitch_SliderClass {
  background-color: var(--lighttheme-black-variant4);
  cursor: not-allowed;
}

input:disabled + .LiSwitch_SliderClass:before {
  background-color: var(--lighttheme-black-variant5);
  cursor: not-allowed;
}

input:checked + .LiSwitch_SliderClass:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.LiSwitch_SliderClass.round {
  margin-top: 2px;
  border-radius: 90px;
  width: 32px;
  height: 14px;

}

.LiSwitch_SliderClass.round:before {
  border-radius: 50%;
}


/* .DarkTheme */

.DarkTheme.LiSwitch_LabelSpan{
  color: var(--white) !important;
}


.DarkTheme.LiSwitch_SliderClass {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--darktheme-white-variant4);
  -webkit-transition: .4s;
  transition: .4s;
}

.DarkTheme.LiSwitch_SliderClass:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -8px;
  bottom: -4px;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .DarkTheme.LiSwitch_SliderClass {
  background-color: var(--darktheme-tufts-blue-variant1);
}

input:checked + .DarkTheme.LiSwitch_SliderClass:before {
  background-color: var(--darktheme-tufts-blue);
}


input:checked + .DarkTheme.LiSwitch_SliderClass:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.DarkTheme.LiSwitch_SliderClass.round {
  border-radius: 34px;
}

.DarkTheme.LiSwitch_SliderClass.round:before {
  border-radius: 50%;
}

input:disabled + .DarkTheme.LiSwitch_SliderClass {
  background-color: var(--darktheme-white-variant5);
  cursor: not-allowed;
}

input:disabled + .DarkTheme.LiSwitch_SliderClass:before {
  background-color: var(--darktheme-white-variant3);
  cursor: not-allowed;
}







input:disabled + .LiSwitch_SliderClass:hover:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.0), 0 0 0 0px rgba(0, 0, 0, 0.0) !important;
  /* transition: none; */
}


input:disabled + .DarkTheme.LiSwitch_SliderClass:hover:before {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 0 0px rgba(0, 0, 0, 0.0) !important;
  /* transition: none; */
}



.LiSwitch_LabelClass .LiSwitch_SliderClass:hover:before {
  box-shadow: 0 1px 3px var(--lighttheme-tufts-blue-variant1), 0 0 0 4px var(--lighttheme-tufts-blue-variant1);
  transition: all, 0.1s;
}

input:checked + .LiSwitch_SliderClass:hover:before {
  box-shadow: 0 1px 3px var(--lighttheme-tufts-blue-variant1), 0 0 0 4px var(--lighttheme-tufts-blue-variant1);
  transition: all, 0.1s;
}


.DarkTheme.LiSwitch_LabelClass .LiSwitch_SliderClass:hover:before {
  box-shadow: 0 1px 3px var(--darktheme-white-variant4), 0 0 0 4px var(--darktheme-white-variant4);
  transition: all, 0.1s;
}

input:checked + .DarkTheme.LiSwitch_SliderClass:hover:before {
  box-shadow: 0 1px 3px var(--darktheme-tufts-blue-variant1), 0 0 0 4px var(--darktheme-tufts-blue-variant1);
  transition: all, 0.1s;
}
