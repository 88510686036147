.FloatingPanel {
	/* display: none; */
	position: absolute;

	background-color: #ffffff;
	color: #000000;

	z-index: 10;	
	transition: cubic-bezier(0.25, 0.46, 0.45, 0.94);

	/* border: 1px solid #1d0101; */

	border: 1px solid var(--lighttheme-black-variant1);
}

.FloatingPanelContent {
	padding: 6px;
	height: calc(100% - 22px);
}

.FloatingPanelClose {
	/* display: block; */

	width: 25px;
	height: 20px;

	position: absolute;

	top: 2px;
	right: 2px;
	
	background-color: rgb(245,245,245);
	color: black;
	
	text-decoration: none;
	text-align: center;
	
	font-size: 13px;
	font-weight: bold;

	border: 1px solid orange;
}
