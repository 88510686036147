.MuiTypography-title-447 {
    color:white;
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    line-height: 1.16667em;
    background-color: #881B4C !important;
}

.LiIconComponent_Icon{
    height: 20px !important;
    width: 20px !important;
    padding: 0px 3px !important;
    margin-top: 7px;
}
   
