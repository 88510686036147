/*spinner */
.LightTheme.LiSpinner_SpinBox input {
  display: block;
  width: 90px;
  height: 28px;
  padding: 0 0.25em;
  border: 0.05em solid var(--lighttheme-black-variant1);
  color: var(--lighttheme-black-variant1);
  font-family: inherit;
  font-size: 1em;
  text-align: right;
  outline: none !important;
  background-color: var(--lighttheme-black-variant5);
}


.LightTheme.LiSpinner_SpinBox input:hover {
  border: 0.05em solid var(--black);
}

.DarkTheme.LiSpinner_SpinBox input:hover {
  border: 0.05em solid var(--white);
}

.LightTheme.LiSpinner_SpinBox input:disabled{
  cursor:not-allowed;
  border: 0.05em solid var(--lighttheme-black-variant2); 
  background-color: var(--lighttheme-black-variant5);
}


.LightTheme.LiSpinner_SpinBox input:disabled:hover{
  border: 0.05em solid var(--lighttheme-black-variant2);
}

.DarkTheme.LiSpinner_SpinBox input:disabled{
  cursor:not-allowed;
  border: 0.05em solid var(--darktheme-white-variant3); 
  background-color: var(--darktheme-black-variant3);
}


.DarkTheme.LiSpinner_SpinBox input:disabled:hover{
  border: 0.05em solid var(--darktheme-white-variant3);
}


.LightTheme.LiSpinner_SpinBox {
  position: relative;
  display: inline-block;
  margin-top: 0.375em;
  padding-right: 2.605em;
}

.LightTheme.LiSpinner_SpinBoxUp {
  top: -0.5px;
  height: 19.5px !important;
}


.LightTheme.LiSpinner_SpinBoxUp, .LightTheme.LiSpinner_SpinBoxDown {
  position: absolute;
  right: 0;
  display: block;
  width: 35px;
  height: 19.5px;
  cursor: pointer;
}


.LightTheme.LiSpinner_SpinBoxUp span {
  background: url(../../images/lispinner/dark_up_arrow.svg) no-repeat center center;
  width: 2px;
  background-color: var(--lighttheme-black-variant1); 
  /* background-size:cover; */
  
}

.LightTheme.LiSpinner_SpinBoxUp span, .LightTheme.LiSpinner_SpinBoxDown span {
  display: block;
  width: 35px;
  height: 15px;
}

.LightTheme.LiSpinner_SpinBoxDown {
  bottom: -1.5px;
  height: 15px !important;
}

.LightTheme.LiSpinner_SpinBoxDown span {
  background: url(../../images/lispinner/dark_down_arrow.svg) no-repeat center center;
  background-color: var(--lighttheme-black-variant1);
  /* background-size:cover */
}

.LightTheme.LiSpinner_SpinBox input:focus{
  border: 0.05em solid var(--tufts-blue);
}

.LightTheme.disabledSpinner{
  position: absolute;
  right: 0;
  display: block;
  width: 35px;
  height: 19.5px;
  cursor: not-allowed;
  
}

.LightTheme.LiSpinner_SpinBoxUp.disabledSpinner span{
  background-color: var(--lighttheme-black-variant4) !important;
  background: url(../../images/lispinner/dark_up_arrow_disable.svg) no-repeat center center;
}

.LightTheme.LiSpinner_SpinBoxDown.disabledSpinner span{
  background-color: var(--lighttheme-black-variant4) !important;
  background: url(../../images/lispinner/dark_down_arrow_disable.svg) no-repeat center center;
}

.DarkTheme.LiSpinner_SpinBoxUp.disabledSpinner span{
  background-color: var(--darktheme-white-variant5) !important;
  background: url(../../images/lispinner/dark_up_arrow_disable.svg) no-repeat center center;
}

.DarkTheme.LiSpinner_SpinBoxDown.disabledSpinner span{
  background-color: var(--darktheme-white-variant5) !important;
  background: url(../../images/lispinner/dark_down_arrow_disable.svg) no-repeat center center;
}


/* Dark Theme */

.DarkTheme.LiSpinner_SpinBox input {
  display: block;
  width: 90px;
  height: 28px;
  padding: 0 0.25em;
  border: 0.05em solid var(--darktheme-white-variant1);
  color: var(--arktheme-white-variant1);
  font-family: inherit;
  font-size: 1em;
  text-align: right;
  outline: none !important;
  background-color: var(--darktheme-black-variant3);
  opacity: 0.7;
}



.DarkTheme.LiSpinner_SpinBox {
  position: relative;
  display: inline-block;
  margin-top: 0.375em;
  padding-right: 2.605em;
}

.DarkTheme.LiSpinner_SpinBoxUp {
  top: 0;
  height: 19.5px !important;
}


.DarkTheme.LiSpinner_SpinBoxUp, .DarkTheme.LiSpinner_SpinBoxDown {
  position: absolute;
  right: 0;
  display: block;
  width: 35px;
  height: 19.5px;
  cursor: pointer;
}

.DarkTheme.LiSpinner_SpinBoxUp span {
  background: url(../../images/lispinner/dark_up_arrow.svg) no-repeat center center;
  background-color: var(--darktheme-white-variant5); 
  /* background-size:cover */
}

.DarkTheme.LiSpinner_SpinBoxUp span, .DarkTheme.LiSpinner_SpinBoxDown span {
  display: block;
  width: 35px;
  height: 15px;
}

.DarkTheme.LiSpinner_SpinBoxDown {
  bottom: -1.5px;
  height: 15px !important;
}

.DarkTheme.LiSpinner_SpinBoxDown span {
  background: url(../../images/lispinner/dark_down_arrow.svg) no-repeat center center;
  background-color: var(--darktheme-white-variant5);
  /* background-size:cover */
}

.DarkTheme.LiSpinner_SpinBox input:focus{
  border: 0.05em solid var(--darktheme-tufts-blue);
}

.DarkTheme.disabledSpinner{
  position: absolute;
  right: 0;
  display: block;
  width: 35px;
  height: 19.5px;
  cursor: not-allowed;
  opacity: 0.3;
}

/* .DarkTheme.disabledSpinner span{
  background-color: var(--darktheme-white-variant5);
} */
