.lichkboxcontainer {
  /* display: block; */
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* padding:20px; */
  margin-left: -10px;
}

/* Hide the browser's default checkbox */
.lichkboxcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.lichkboxcheckmark {
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 0.5px;
  /* background-color: #eee; */
  line-height: 20px !important;
}

.LightTheme.lichkboxcheckmark {
  border: 1px solid var(--black);
}

.DarkTheme.lichkboxcheckmark {
  border: 1px solid var(--darktheme-white-variant1);
}

/* When the checkbox is checked, add a blue background */
.LightTheme.lichkboxcontainer input:checked ~ .lichkboxcheckmark {
  background-color: var(--white);
  border-color: var(--white);
  opacity: 1;
}

.DarkTheme.lichkboxcontainer input:checked ~ .lichkboxcheckmark {
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 0.5px !important;
  opacity: 1;
}

/* Create the lichkboxcheckmark/indicator (hidden when not checked) */
.lichkboxcheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the lichkboxcheckmark when checked */
.lichkboxcontainer input:checked ~ .lichkboxcheckmark:after {
  display: block;
}

/* Style the lichkboxcheckmark/indicator */
.lichkboxcontainer .lichkboxcheckmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  
  
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity:1;
}

.LightTheme.lichkboxcontainer .lichkboxcheckmark:after {
  border: solid var(--tufts-blue);
  border-width: 0 1px 1px 0;
}

.DarkTheme.lichkboxcontainer .lichkboxcheckmark:after {
  border: solid var(--darktheme-tufts-blue);
  border-width: 0 1px 1px 0;
}

.lichkboxcheckmarkSpan{
  padding-left: 30px !important;
  text-align: center !important;
}