.LiDatePicker_Container {
    display: inline-block;
    position: relative;
    /* border: 2px solid orange; */
}

label.LiDatePicker_FloatingLabel {
    font-size: 13px;
    float: left;
}

.LiDatePicker_FormGroup {
    /* margin-top: 5px; */
    position: relative;
    /* border: 2px solid yellow; */
}

.LiDatePicker_Input {
    /* width: 100%; */
    height: 30px;
    font-size: 13px;

    padding-left: 10px;
    padding-right: 10px;

    letter-spacing: 1px;
    outline: none;
    text-align: left;
}

.LightTheme.LiDatePicker_Input {
    color: var(--black);
    background-color: var(--lighttheme-black-variant5);
    border: 0.75px solid var(--lighttheme-black-variant1);
}

.DarkTheme.LiDatePicker_Input {
    color: var(--white);
    background-color: var(--darktheme-black-variant3);
    border: 0.75px solid var(--darktheme-white-variant1);
}

.LightTheme.LiDatePicker_InputIcon:hover + .LightTheme.LiDatePicker_Input {
    border: 0.75px solid var(--lighttheme-black-variant1) !important;
}
.DarkTheme.LiDatePicker_InputIcon:hover + .DarkTheme.LiDatePicker_Input {
    border: 0.75px solid var(--white) !important;
}

.LightTheme.LiDatePicker_Input:hover {
    border: 0.75px solid var(--lighttheme-black-variant1);
}

.LightTheme.LiDatePicker_Input:focus {
    border: 0.75px solid var(--tufts-blue);
}

.LightTheme.LiDatePicker_Input:disabled {
    border: 0.75px solid var(--lighttheme-black-variant1);
    opacity: 0.4;
}

.LightTheme.LiDatePicker_Input:disabled:hover {
    border: 0.75px solid var(--lighttheme-black-variant1);
    cursor: not-allowed;
}

.LightTheme.LiDatePicker_Input::placeholder {
    color: var(--black);
    font-size: 13px;
}

.DarkTheme.LiDatePicker_Input:hover {
    border: 0.75px solid var(--white) !important;
}

.DarkTheme.LiDatePicker_Input:focus {
    border: 0.75px solid var(--darktheme-tufts-blue) !important;
}

.DarkTheme.LiDatePicker_Input::placeholder {
    color: var(--white);
}

.DarkTheme.LiDatePicker_Input:disabled {
    border: 0.75px solid var(--darktheme-white-variant1);
    opacity: 0.4;
}

.DarkTheme.LiDatePicker_Input:disabled:hover {
    border: 0.75px solid var(--white);
    cursor: not-allowed;
}

.LiDatePicker_InputIcon {
    width: 24px;
    height: 26px;

    position: absolute;
    bottom: 6px;
    right: 2px;
    
    /* position: relative;
    top: 4px;
    right: 26px; */

    border-style: none;
    pointer-events: auto;
    cursor: pointer;    
}

.LightTheme.LiDatePicker_InputIcon {
    background: url("../../images/lidatepicker/LightTheme/date_picker.svg") no-repeat;
}

.DarkTheme.LiDatePicker_InputIcon {
    background: url("../../images/lidatepicker/DarkTheme/date_picker.svg") no-repeat;
}

.LiDatePicker_InputIconDisabled {
    width: 24px;
    height: 24px;

    position: absolute;
    bottom: 6px;
    right: 2px;

    /* position: relative;
    top: 4px;
    right: 26px; */
    
    border-style: none;
    pointer-events: none;
    cursor: not-allowed;
}

.LightTheme.LiDatePicker_InputIconDisabled {
    background: url("../../images/lidatepicker/LightTheme/date_picker_inactive.svg") no-repeat;
}

.DarkTheme.LiDatePicker_InputIconDisabled {
    background: url("../../images/lidatepicker/DarkTheme/date_picker_inactive.svg") no-repeat;
}

.LiDatePicker_Error {
    /* position: inherit; */
    display: inline-block;
    position: relative;

    /* float: left; */
    /* top: 10px;
    right: 20px; */
    
    /* text-align: left;
    padding-top: 2px !important; */

    /* border: 1px solid red; */
}

.LiDatePicker_ErrorLabel {
    display: inline-block;

    font-size: 13px;
    color: rgb(192, 43, 6);
    padding-top: 0px !important;

    /* border: 1px solid green; */
}

.LiDatePicker_Dropdown {
    position: relative;
}

.LiDatePicker_DropdownMenu {
    width: 279px;
    min-width: 240px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}


/* ###################################### Calendar dropdown style ###################################### */

.LiDatePicker_CalendarContainer {
    transition: 100ms ease-out;
    /* transition-delay: 100ms; */
    /* overflow: hidden; */
    height: 0;
    padding: 10px 20px;
}

.LightTheme.LiDatePicker_CalendarContainer {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);

    border: 1px solid var(--lighttheme-black-variant1);
}

.DarkTheme.LiDatePicker_CalendarContainer {
    background-color: var(--darktheme-black-variant3);
    color: var(--white);

    border: 1px solid var(--darktheme-white-variant1);
}

.LiDatePicker_CalendarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    /* border: 1px solid red; */
}

.LiDatePicker_CalendarHeaderContent {
    margin: 0 auto;

    font-size: 15px;
    text-align: center;
    user-select: none;

    /* width: 90%; */
    min-width: 110px;

    /* border: 1px solid green; */
    cursor: pointer;
}

.LightTheme.LiDatePicker_CalendarHeaderContent {
    /* color: #30373D; */
    color: var(--black);
}

.DarkTheme.LiDatePicker_CalendarHeaderContent {
    color: var(--white);
}

.LiDatePicker_ArrowLeft {
    /* margin-left: 10px; */
    width: 20px;
    height: 20px;

    background-size: cover;
    cursor: pointer;

    /* margin: 2px 4px; */

    /* border: 1px solid yellow; */
}

.LightTheme.LiDatePicker_ArrowLeft {
    background-image: url("../../images/lidatepicker/LightTheme/left_arrow.svg");
}

.DarkTheme.LiDatePicker_ArrowLeft {
    background-image: url("../../images/lidatepicker/DarkTheme/left_arrow.svg");
}

/* .LightTheme.LiDatePicker_ArrowLeft:hover {
    background-image: url("../../images/lidatepicker/LightTheme/left_arrow_hover.svg");
    background-image: url("../../images/lidatepicker/DarkTheme/left_arrow_hover.svg");

    
} */

.DarkTheme.LiDatePicker_ArrowLeft:hover,
.LightTheme.LiDatePicker_ArrowLeft:hover {
    background-image: url("../../images/lidatepicker/DarkTheme/left_arrow_hover.svg");
}

.LiDatePicker_ArrowRight {
    /* margin-right: 10px; */
    width: 20px;
    height: 20px;

    background-size: cover;
    /* object-fit: cover; */
    cursor: pointer;

    /* margin: 8px 7px; */

    /* border: 1px solid yellow; */
}

.LightTheme.LiDatePicker_ArrowRight {
    background-image: url("../../images/lidatepicker/LightTheme/right_arrow.svg");
    /* padding-right: 5px; */
}

.DarkTheme.LiDatePicker_ArrowRight {
    background-image: url("../../images/lidatepicker/DarkTheme/right_arrow.svg");
}

/* .LightTheme.LiDatePicker_ArrowRight:hover {
    background-image: url("../../images/lidatepicker/LightTheme/right_arrow_hover.svg");
} */

.DarkTheme.LiDatePicker_ArrowRight:hover,
.LightTheme.LiDatePicker_ArrowRight:hover {
    background-image: url("../../images/lidatepicker/DarkTheme/right_arrow_hover.svg");
}

.LiDatePicker_TodayBtn {
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;

    text-align: center;
    user-select: none;
    padding: 6px 4px;

}

.LightTheme.LiDatePicker_TodayBtn {
    border: 0.50px solid var(--lighttheme-black-variant1);
    color: var(--black);
}

.DarkTheme.LiDatePicker_TodayBtn {
    /* border: 0.50px solid var(--white); */
    color: var(--white);
}

.LiDatePicker_CalendarDateGridHighlighted {
    /* color: red !important;
    border: 1px solid red; */
    /* position: relative; */
}

.LiDatePicker_CalendarDateGridHighlighted::before {
    position: absolute;
    border: 1px solid blue;
}

.LightTheme.LiDatePicker_CalendarDateGridHighlighted {    
    color: var(--white) !important;
    border: 1px solid var(--tufts-blue);
    background: var(--tufts-blue);
    /* border: 4px solid green; */
}

.DarkTheme.LiDatePicker_CalendarDateGridHighlighted {
    color: var(--white) !important;
    border: 1px solid var(--darktheme-tufts-blue);
}

.LiDatePicker_CalendarDateGridToday {    
    background: var(--tufts-blue) !important;
    border: 1px solid transparent;
}

.LightTheme.LiDatePicker_CalendarDateGridToday {    
    color: var(--white) !important;
}

.DarkTheme.LiDatePicker_CalendarDateGridToday {
    color: var(--white) !important;
}

.LiDatePicker_CalendarDateGridToday::after {
    position: absolute;
    right: 0;
    bottom: 0;
}

.LiDatePicker_CalendarDateGrid {
    height: 24px;
    line-height: 24px;

    vertical-align: middle;
    text-align: center;

    padding: 4px 3px;

    font-size: 13px;
    user-select: none;

    transition: all .4s ease-out;
}

.LightTheme.LiDatePicker_CalendarDateGrid {
    color: var(--black);
}

.DarkTheme.LiDatePicker_CalendarDateGrid {
    color: var(--white);
}

.LightTheme.LiDatePicker_CalendarDateGrid:hover {
    /* color: var(--tufts-blue); */
    background: var(--lighttheme-tufts-blue-variant1);
}

.DarkTheme.LiDatePicker_CalendarDateGrid:hover {
    /* color: var(--darktheme-tufts-blue); */
    background: var(--darktheme-yale-blue);
}

.LiDatePicker_textColorDisabled {
    color: rgba(128, 128, 128, 0.308) !important;
}

.LiDatePicker_textColorDisabled:hover{
    color: red !important;
}

.LiDatePicker_textColorOutOfMonth {
    color: #A3A3A3 !important;
}

.LightTheme.LiDatePicker_textColorOutOfMonth:hover{
    color: var(--tufts-blue) !important;
}

.DarkTheme.LiDatePicker_textColorOutOfMonth:hover{
    color: var(--darktheme-tufts-blue) !important;
}

.LiDatePicker_cursorNotAllowed {
    cursor: not-allowed;    
}

.LiDatePicker_CalendarGrid {	
    display: grid;	
    grid-template: repeat(7, auto) / repeat(7, auto);	
    grid-column-gap: 3px;	
    grid-row-gap: 0px;	
    /* border: 1px solid purple; */	
}

.LiDatePicker_cursorPointer {
    cursor: pointer;
}

.LiDatePicker_CalendarDay {
    height: 40px;
    line-height: 40px;

    vertical-align: middle;
    text-align: center;
    font-size: 13px;

    color: #A3A3A3;
}

.LiDatePicker_YearsGrid {
    margin-top: 15px;

    display: grid;
    grid-template: repeat(5, auto) / repeat(4, auto);
    grid-column-gap: 3px;
    grid-row-gap: 0px;
    /* border: 1px solid purple; */
}

.LiDatePicker_YearCell {
    height: 25px;
    line-height: 25px;

    vertical-align: middle;
    text-align: center;
    font-size: 13px;

    /* color: #A3A3A3; */
    font-weight: bold;

    margin: 8px 3px;
    cursor: pointer;
    border: 1px solid #A3A3A3;
}

.LiDatePicker_MonthsGrid {
    margin-top: 15px;

    display: grid;
    grid-template: repeat(4, auto) / repeat(3, auto);
    grid-column-gap: 3px;
    grid-row-gap: 0px;
    /* border: 1px solid purple; */
}

.LiDatePicker_MonthCell {
    height: 25px;
    line-height: 25px;

    vertical-align: middle;
    text-align: center;
    font-size: 13px;

    /* color: #A3A3A3; */
    font-weight: bold;

    margin: 15px 3px;
    cursor: pointer;
    border: 1px solid #A3A3A3;
}

