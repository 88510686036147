.LiAvatarEditor {
    width: 274px;
    margin: 15px auto;
    position: relative;
}

.LiAvatarRotate {
    /* float: right; */
    /* vertical-align: bottom; */
    position: absolute;
    bottom: 20px;
    right: 0;
}

.LiAvatarRotate img {
    width: 20px;
    margin-right: 10px;
}

.avatareditorcanvas{
    float: left;
}