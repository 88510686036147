.LiCalendarList_calendarContainer {
	/* border: 1px solid red;  */
}

.LightTheme.LiCalendarList_calendarContainer {
	background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiCalendarList_calendarContainer {
	background-color: var(--darktheme-black-variant3);
}

.LiCalendarList_calendarHeader {
	/* height: 40px; */

	padding-left: 6px;
	padding-right: 6px;
	padding-top: 6px;
	
	user-select: none;
	display: flex;
	/* border: 1px solid red; */
}

.LightTheme.LiCalendarList_calendarHeader {
	background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiCalendarList_calendarHeader {
	background-color: var(--darktheme-black-variant3);
}

.LiCalendarList_LeftArea {
	display: flex;

	min-width: 200px;

	/* border: 1px solid green; */
}

.LiCalendarList_RightArea {
	margin-left: auto;
}

.LiCalendarList_calendarIcon {
	/* display: inline-block; */

	/* border: 1px solid orange; */
}

.LiCalendarList_headerRight {
	display: flex;
}

.LiCalendarList_calendarImgMain {
	vertical-align: middle;
	width: 24px;
	cursor: pointer;	

	/* border: 1px solid cyan; */
}

.LiCalendarList_monthName {
	font-size: 16px;
	font-weight: bold;
	padding: 0px 6px;
	margin-left: 4px;

	min-width: 120px;
	display: inline-block;

	/* border: 1px solid yellow; */
}

.LiCalendarList_navigation {
	margin-left: auto;
}

.LiCalendarList_prevMonth, .LiCalendarList_nextMonth {
	width: 20px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;

	cursor: pointer;

	/* border: 1px solid blue; */
}

.LiCalendarList_calendarImgNav {
	vertical-align: middle;
	width: 8px;
	/* border: 1px solid orange; */
}


.LiCalendarList_listView, .LiCalendarList_gridView {
	cursor: pointer;
	margin-left: 6px;
	float: right;
	/* border: 1px solid blue; */
}

.LiCalendarList_calendar {
	width: 100%;
	height: 670px;
	overflow-y: auto;
	/* pointer-events: none; */
}

.LiCalendarList_day {
	z-index: 1;

	letter-spacing: 1px;
	font-size: 16px;
	font-weight: bold;
	user-select: none;

	display: flex;
	flex-direction: row;

	min-height: 40px;
	padding: 0 4px;

	pointer-events: auto;
	cursor: pointer;

	/* overflow-y: auto; */
	/* border: 1px solid green; */
}

.LiCalendarList_dayMouseOverBorder {
	border: 1px solid green !important;
}

.LightTheme.LiCalendarList_day {
	color: var(--black);
	background-color: var(--lighttheme-black-variant5);

	border-bottom: 1px solid var(--lighttheme-black-variant1);

	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

.DarkTheme.LiCalendarList_day {
	color: var(--white);
	background-color: var(--darktheme-black-variant3);

	border-bottom: 1px solid var(--darktheme-white-variant1);

	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

.LiCalendarList_dayLeft {
	display: flex;
	flex-direction: column;

	min-width: 30px;

	padding: 0 2px;

	/* border: 1px solid purple; */
}

.LiCalendarList_dayRight {
	display: flex; 
	flex-direction: row;

	align-items: center;

	/* min-width: 200px; */
	/* font-size: 16px; */

	font-size: 14px;
	font-weight: normal;

	/* border: 1px solid purple; */
}

.LiCalendarList_dayRightFlex {
	/* display: flex; */

	display: flex; 
	flex-direction: row;

	align-items: center;
}

.LiCalendarList_dayRightNone {
	display: none;
}



.LiCalendarList_dayLeft > div:first-child {
	text-align: left;
	font-size: 9px;
	text-transform: uppercase;

	padding-top: 8px;

	/* border: 1px solid cyan; */
}

.LiCalendarList_dayLeft > div:nth-child(2) {
	text-align: left;
	font-size: 14px;

	display: flex;
	flex-direction: column;

	/* border: 1px solid yellow; */
}

/* .LiCalendarList_dayRight > div {
	flex: 0 0 auto;
}

.LiCalendarList_dayRight > div:first-child {
	margin-bottom: auto;
} */


.LiCalendarList_dayDisabled {
	color: #9a9ea1 !important;
}

.LiCalendarList_today {
	background-color: #c7c7c7;	
}

.LightTheme.LiCalendarList_today {
	border: 1px solid var(--tufts-blue) !important;
}

.DarkTheme.LiCalendarList_today {
	border: 1px solid var(--darktheme-tufts-blue) !important;
}

/* .LightTheme.LiCalendarList_task {
	color: #ffffff;
	background-color: var(--LightTheme_backgroundColorHover);
}

.DarkTheme.LiCalendarList_task {
	color: #ffffff;
	background-color: var(--DarkTheme_backgroundColorHover);
} */

.LiCalendarList_circle {	
	display: flex;

	justify-content: center;
	align-items: center;

	height: 25px;
	margin: 2px 1px;

	/* border-radius: 50%; */
	/* width: 25px; */

	/* NEW DESIGN BADGE */
	border-radius: 3px;
	padding: 0 6px;
	/* NEW DESIGN BADGE */
}



.LightTheme.LiCalendarList_circle {
	color: var(--white);
}

.DarkTheme.LiCalendarList_circle {
	color: var(--darkTheme-color);
}

.LiCalendarList_circle_BLACK {
	background-color: #000207;
}

.LiCalendarList_circle_GREEN {
	background-color: #348401;
}

.LiCalendarList_circle_YELLOW {
	background-color: #da9c55;
}

.LiCalendarList_circle_RED {
	background-color: #c70623;
}


.LiCalendarList_taskType_BLACK {
	border-left-color: #000207;
}

.LiCalendarList_taskType_GREEN {
	border-left-color: #348401;
}

.LiCalendarList_taskType_YELLOW {
	border-left-color: #e5941f;
}

.LiCalendarList_taskType_RED {
	border-left-color: #c70623;
}


.LiCalendarList_daySelected {
}

.LightTheme.LiCalendarList_daySelected {
	background-color: var(--tufts-blue) !important;
}

.DarkTheme.LiCalendarList_daySelected {
	background-color: var(--darktheme-tufts-blue) !important;
}


.LiCalendarList_dayDragOver {
}

.LightTheme.LiCalendarList_dayDragOver {
	background-color: var(--lighttheme-tufts-blue-variant1) !important;
}

.DarkTheme.LiCalendarList_dayDragOver {
	background-color: var(--darktheme-tufts-blue-variant1) !important;
}


.LiCalendarList_overlayOuter {
	/* border: 1px solid cyan; */
}

.LiCalendarList_overlayRowItem {
	display: flex;
	width: 210px;
	padding: 2px;
	margin: 1px;

	/* border: 1px solid orange; */
}

.LiCalendarList_overlayTitle {
	float: left;
	width: 130px;
	/* border: 1px solid green; */
}

.LiCalendarList_overlayValue {
	float: left;
	width: calc(100% - 130px);
	/* border: 1px solid red; */
}
