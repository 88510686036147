/************************ HORIZONTAL PROGRESS  *************************/
.barDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.progressDiv {
  width: 100%;
  background-color: var(--lighttheme-black-variant4);
  position: relative;
  margin-right: 4px;
}


/************************ CIRCLE PROGRESS *************************/
.circleBackground,
.circleProgress {
  fill: none;
}

.circleBackground {
  stroke: var(--lighttheme-black-variant4);
}

.circleProgress {
  /* stroke: #881b4c; */
  stroke-linecap: round;
}

.circleText {
  font-size: 1em;
  /* font-weight: bold;   */
}

.LightTheme.circleText {
  fill: var(--black);
}

.DarkTheme.circleText {
  fill: var(--white);
}


/* DEMO PAGE */

input[type="file"].fileReader {
  width: initial !important;
  height: initial !important;
  cursor: pointer;
}


@keyframes change {
  from {background-position:0 0,left}
  to {background-position:30px 0,left}
}

.progressbarLabel{
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}
