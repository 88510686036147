.LiDropdownMenu_menuContainer {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 1px solid red; */
}

.DarkTheme .LiDropdownMenu_menuContainer {
    background-color: var(--darktheme-black-variant3);
    color: var(--white);
}

.LightTheme .LiDropdownMenu_menuContainer {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);
}

.LiDropdownMenu_menuTrigger {
    width: 100%;

    margin-left: auto; /* for positioning */

    box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
    transition: box-shadow 0.4s ease;

    height: 32px !important;

    border: none;
    vertical-align: middle;
    border-radius: 4px;
    padding: 4px 6px;
    cursor: pointer;
}

.LiDropdownMenu_menuTrigger:focus {
    outline: none;
}

/* .DarkTheme .LiDropdownMenu_menuTrigger:focus {
    background: var(--darkTheme-highlightColor);
    color: var(--darkTheme-color);
}

.LightTheme .LiDropdownMenu_menuTrigger:focus {
    background: var(--lightTheme-highlightColor);
    color: var(--black);
} */


.DarkTheme .LiDropdownMenu_menuTrigger {
    background-color: var(--darktheme-black-variant3);
    color: var(--white);
}

.LightTheme .LiDropdownMenu_menuTrigger {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);
}


.LiDropdownMenu_menuTriggerIcon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 12px;
}

.LiDropdownMenu_menuTrigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.LiDropdownMenu_menuTrigger span {
    font-size: 14px;
}

.LiDropdownMenu_menuDropdown {
    width: 100%;
    position: absolute;
    right: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    /* transform: translateY(-1px); */
    /* transition: opacity 0.4s linear, transform 0.4s linear, visibility 0.4s linear; */
}

.DarkTheme .LiDropdownMenu_menuDropdown {
    background-color: var(--darktheme-black-variant3);
    color: var(--white);
}

.LightTheme .LiDropdownMenu_menuDropdown {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);
}

.LiDropdownMenu_menuDirectionUpwards {
    bottom: 33px;
}

.LiDropdownMenu_menuDirectionDownwards {
    top: 33px;
}

.LiDropdownMenu_menuDropdown.LiDropdownMenu_active {
    opacity: 1;
    visibility: visible;

    z-index: 100;
    /* transform: translateY(0); */
}

.LiDropdownMenu_menuDropdown ul {
    /* list-style: none; */
    padding: 0;
    margin: 0;
}

.LiDropdownMenu_menuDropdown li {
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 10px;    
}

.DarkTheme .LiDropdownMenu_menuDropdown li {
    background-color: var(--darktheme-black-variant3);
    color: var(--white);
    border-bottom: 1px solid var(--darktheme-white-variant1);
}
.DarkTheme .LiDropdownMenu_menuDropdown li:last-child { 
    border-bottom: none; 
}

.LightTheme .LiDropdownMenu_menuDropdown li {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);
    border-bottom: 1px solid var(--lighttheme-black-variant1);
}

.LightTheme .LiDropdownMenu_menuDropdown li:last-child { 
    border-bottom: none; 
}
