.LiCheckBox {
    display: none;
    /* position: absolute;
    left: -9999px; */
}

.liCheckContainer {
    width: 100%;
    margin: 0.25em auto;
    display: contents;
}

.test {
    background-color: "red";
}

.liCheckContainer label {
    position: relative;
    /* margin-left: 5px;
    margin-right: 50px; */
}

.LiCheck_labelClass {
    /* color: var(--LightTheme_textColorSelected) !important; */
    /* line-height: 2 !important; */
    margin-left: 40px;
}

.liCheckContainer span::before,
.liCheckContainer span::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: 1px;
    margin-top: 0px;
}

.liCheckContainer span.DarkTheme {
    width: 22px;
    height: 22px;
    position: absolute;
    box-sizing: border-box;
    border : 1.2px solid var(--white) !important;
    opacity: calc(54/100);
    padding-right: 10px !important;
    /* background-color: var(--tufts-blue); */
}


.liCheckContainer .LiCheck_CircleClass.DarkTheme:hover:not(.selected){
    transform: scale(2);
    background: var(--darktheme-yale-blue);
    opacity: calc(12/100);
    border: 1.2px solid transparent !important;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.liCheckContainer span.DarkTheme.disabled {
    opacity: calc(12/100) !important;
}

.liCheckContainer span.DarkTheme.noHover {
    background: inherit; 
    opacity: 0 !important;
}

.liCheckContainer span.DarkTheme.noHover:hover {
    /* opacity: 0; */
    cursor: not-allowed;
}

.liCheckContainer span.DarkTheme.selected:before {
  /* border : 2px solid var(--darkTheme-hoverColor);
  opacity: 54%;
  border-radius: 0%;
  transform: scale(1); */
  /* background-color:var(--darkTheme-highlightColor) !important; */
}

/* 
.liCheckContainer span.DarkTheme.selected:hover:before {
  background-color:var(--darkTheme-highlightColor) !important;
}

.liCheckContainer span.DarkTheme.selected:hover:after {
  background-color:inherit !important;
  transform: rotate(45deg), scale(1);
  border-radius: 0%;
} */


.LiCheck_SpanClass.DarkTheme.selected {
    background-color: var(--tufts-blue);
    opacity: 1 !important;
    border: none;
}

.LiCheck_SpanClass.DarkTheme.selected::after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.LiCheck_Intermediate.DarkTheme.selected {
    background-color: var(--tufts-blue);
    opacity: 1 !important;
    /* border: 2px solid rgba(0, 0, 0, 12%) !important; */
}

.LiCheck_Intermediate.DarkTheme.selected::after {
    content: "";
    color: white;
    position : absolute;
    top : 7px;
    left : 50%; 
    width : 10px;
    height : 18px;
    border-right: 3px solid white;
    transform: translate(-50%, -50%) rotateZ(90deg);
    transition: all 0.4s;
}

/*******************************************************Light Theme**********************************/

.liCheckContainer span.LightTheme {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    border : 1.2px solid var(--black) !important;
    opacity: calc(54/100);
    padding-right: 10px !important;
    /* background-color: var(--tufts-blue); */
}

.liCheckContainer .LiCheck_CircleClass.LightTheme:hover:not(.selected) {
    transform: scale(2);
    background: var(--darktheme-yale-blue) !important;
    opacity: calc(12/100);
    border: 1.2px solid var(--darktheme-yale-blue) !important;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.liCheckContainer span.LightTheme.disabled {
    opacity: calc(12/100) !important;
}

.liCheckContainer span.LightTheme.noHover {
    background: inherit;
    opacity: 0 !important;

    border: 1.2px solid rgb(0, 0, 0, 0.9);
}

.liCheckContainer span.LightTheme.noHover:hover {
    /* opacity: 12%; */
    cursor: not-allowed;
}

.liCheckContainer span.LightTheme.selected:before{
    /* border : 2px solid rgb(0, 0, 0,0.5) !important;
    background-color:var(--lightTheme-highlightColor) !important; */
    /* opacity: 54%; */
    /* border-radius: 0%;
    transform: scale(1); */
 }


/* 
.liCheckContainer span.LightTheme.selected:hover:before {
    border : 2px solid var(--LightTheme_borderColorHover) !important;
    background-color:var(--lightTheme-highlightColor) !important;
}

.liCheckContainer span.LightTheme.selected:hover:after {
    background-color:inherit !important;
    transform: rotate(45deg), scale(1);
    border-radius: 0%;
} */


/* .liCheckContainer span.LightTheme::after {
    transform: scale(1);
    width: 20px;
    height: 20px;
    margin-left: 2px;
    border : 2px solid var(--LightTheme_borderColorHover);
    opacity: 54%;
    transition: color .2s;
} */


.LiCheck_SpanClass.LightTheme.selected {
    background-color: var(--tufts-blue);
    opacity: 1 !important;
    /* border: 2px solid rgba(0, 0, 0, 12%) !important; */
}

.LiCheck_SpanClass.LightTheme.selected::after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    /* opacity: 1; */
}

.LiCheck_Intermediate.LightTheme.selected {
    background-color: var(--tufts-blue);
    opacity: 1 !important;
    border: none;
}

.LiCheck_Intermediate.LightTheme.selected::after {
    content: "";
    color: white;
    position : absolute;
    top : 7px;
    left : 50%; 
    width : 10px;
    height : 18px;
    border-right: 3px solid white;
    transform: translate(-50%, -50%) rotateZ(90deg);
    transition: all 0.4s;
}

.liCheckContainer span.selected{
    border: none !important;
}
/* #check{
  .checkmark::after {
    content: "";
    color: white;
    position : absolute;
    top : 7px;
    left : 50%; 
    width : 10px;
    height : 18px;
    border-right: 3px solid white;
    boder-bottom : 3px solid white;
    transform: translate(-50%, -50%) rotateZ(90deg);
    transition: all 0.4s;
  }
 */
