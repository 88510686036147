.LiActionMenu_Dropdown {
    position: relative;
    /* border: gold 1px solid; */
}

.LiActionMenu_DropdownMenu {
    position: absolute;
    top: 0px !important;
    z-index: 1;
    background-color:gainsboro;
    /* border-bottom-left-radius: 5px;  
    border-bottom-right-radius: 5px; */
    /* border: green 1px solid; */
}

.LiActionMenu_DropdownMenu_Pos1 {
    left: 0 !important;
}

.LiActionMenu_DropdownMenu_Pos2 {
    left: -230px !important;
}

.LiActionMenu_DropdownMenu_Pos3 {
    /* top: -200px !important; */
}

.LiActionMenu_Container {
    /* transition: 580ms ease-out; */
    overflow: hidden;
    min-width: 250px;
    min-height: 50px;
    /* padding: 10px 10px; */
    
    /* color: #30373D; */
    border: var(--lighttheme-black-variant1) 1px solid;
    box-shadow: red;
    /* border-bottom-left-radius: 5px;  
    border-bottom-right-radius: 5px; */
    word-wrap: break-word;
    float: left;
    
    line-height: 20px;
    user-select: none;
    position: fixed;
}

.LiActionMenuLabel {
    /* padding: 0px !important; */
    padding-left: 10px !important;
    margin:0px !important;
    font-weight: bold !important;
    padding-bottom: 6px !important;
    /* border: 1px solid red; */
    width: 100%;
}

.LiActionMenuGridContainer {
    display: grid;
    grid-template-columns: 20% 80%;
    width: 100%;
    height: 40px;
    /* border: 1px solid red; */
    line-height: 3;
    cursor: pointer;
    padding-left: 10px;
}

.LiActionMenuGridContainerFull {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    height: 40px;
    /* border: 1px solid red; */
    line-height: 3;
    cursor: pointer;
    padding-left: 10px;
}

.LiActionMenuHeaderGrid {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    height: 30px;
    /* border: 1px solid red; */
    line-height: 3;
    padding-left: 10px;
}

.LiActionMenuGridContainerItem {
    /* padding-top: 5px; */
    vertical-align: center;
    /* text-align: -webkit-center !important; */
    /* line-height: 0; */
    /* border: 1px solid blue; */
    background: none !important;
    padding-bottom: 5px !important;

}

.LiActionMenu {
    padding-top: 5px !important;
    text-decoration: none !important;
    display: inline-block;
}

.LiActionMenu a {
    color: #1075b9;
    font-weight: bold;
}
  

.LiActionMenu a:link {
    text-decoration: none !important;
}
    
.LiActionMenu a:visited {
    text-decoration: none !important;
    color: #1075b9;
}
  
.LiActionMenu a:hover {
    text-decoration: none !important;
    color: #1075b9;
}

/* .LiActionMenu_Container::after {
    content: "";
    position: absolute;
    bottom: 100%;
    
    margin-left: 5px;
    border-width: 9px;
    border-style: solid;
} */

/* .LiActionMenu_Container_After_Pos1 .LiActionMenu_Container::after  {
    left: 3%;
}

.LiActionMenu_Container::after {
    left: 90% !important;
} */

.hrcolor {
    height: 0.5px;
    border: none;
}

.actionIcon {
    height: 17px;
    border-style: none;
    vertical-align: middle !important;
    /* margin-left: 12px;
    margin-right: 12px; */
    cursor: pointer;
    user-select: none;

    /* border: 1px solid red; */
}

.actionIconVertical {
    width: 17px !important;
}

.actionIconHorizontal{
    width: 20px !important;
}



.LightTheme.LiActionMenu_DropdownMenu {
    background-color: var(--lighttheme-black-variant5);
}

.LightTheme.LiActionMenu_Container {
    background-color: var(--lighttheme-black-variant5);
    /* box-shadow: 5px 10px 8px 4px var(--lighttheme-black-variant5); */
}

.LightTheme.LiActionMenu_Container::after {
    border-color: transparent transparent var(--lighttheme-black-variant5) transparent
}

.LightTheme.LiActionMenuComboStyle {
    width: 130px !important;
}
 

.LightTheme.liActionPanelImg {
    width: 20px !important;
    cursor: pointer;
    padding-top: 10px !important;
    /* border: 1px solid red; */
}


.LightTheme.LiActionMenuGridContainer:hover {
    background-color: var(--lighttheme-black-variant1) ;
    color: var(--white);
}

.LightTheme.LiActionMenuGridContainerFull:hover {
    background-color: var(--lighttheme-black-variant1) ;
    color: var(--white);
}

.LightTheme.hrcolor{
    background-color: var(--lighttheme-black-variant1);
    height: 0.5px;
}



.DarkTheme.LiActionMenuGridContainerFull:hover {
    background-color: var(--darktheme-black-variant1) ;
    color: var(--white);
}

.DarkTheme.LiActionMenuGridContainer:hover {
    background-color: var(--darktheme-black-variant1);
    color: var(--white);
}

.DarkTheme.hrcolor{
    background-color: var(--darktheme-white-variant1);
    height: 0.5px;
}

.DarkTheme.LiActionMenu_DropdownMenu {
    background-color: var(--darktheme-black-variant3);
}

.DarkTheme.LiActionMenu_Container {
    background-color: var(--darktheme-black-variant3) ;
}

.DarkTheme.LiActionMenu_Container::after {
    border-color: transparent transparent var(--darktheme-black-variant3) transparent
}

.DarkTheme.LiActionMenuComboStyle {
    width: 145px !important;
}
  
.DarkTheme.liActionPanelImg {
    width: 20px !important; 
    cursor: pointer;
    padding-top: 10px !important;
}

.LiActionmenu_ParentNode {
    /* float: right;
    position: relative; */

    /* border: 1px solid green; */
}

.DropdownContent a {
    color: black;
    /* padding: 12px 16px; */
    text-decoration: none;
    display: block;
    z-index: 9999;
    position: relative;
}

.DarkTheme.DropdownContent a {
    color: white;
}

.ActionContent_DisabledClass{
    pointer-events: none !important;
    opacity: 0.3;
}

.ActionContent_HiddenClass{
    display: none;
}