.breadCrumbsHeader{
  height: 30px;
  line-height: 30px;
  padding: 8px;      
} 

.DarkTheme.breadCrumbsHeader{
  background-color: var(--darktheme-black-variant1);
}

.LightTheme.breadCrumbsHeader{
  background-color: var(--lighttheme-black-variant5);
}


.DarkTheme.LiBreadCrumbsArrow {
background: url("../../images/libreadcrumbs/DarkTheme/arrow.svg") no-repeat;
width: 0px;
height: 0px;
padding: 5px 5px 5px 5px;
border-style: none;
/* border: 1px solid red !important; */
}

.DarkTheme.LiBreadCrumbsUsr {
/* background: url("../images/DarkTheme/user_management.svg") no-repeat; */

width: 20px;
height: 20px;
/* padding: 8px 8px 8px 8px; */
border-style: none;
/* border: 1px solid red !important; */
/* line-height: 2 !important; */
vertical-align: middle !important;
padding-left: 2px;
margin-left: 12px;
margin-right: 12px;
}

.DarkTheme.LiBreadCrumbsDispSpan a {
  color: var(--white);
  font-weight: bold;
}

.DarkTheme.LiBreadCrumbsDispSpan a:link{
text-decoration: none !important;
}

.DarkTheme.LiBreadCrumbsDispSpan a:visited{
  text-decoration: none !important;
  color: var(--white);
}

.DarkTheme.LiBreadCrumbsDispSpan a:hover{
  text-decoration: none !important;
  color: var(--white);
}




.DarkTheme.LiBreadCrumbsDispSpanRoot a {
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
}

.DarkTheme.LiBreadCrumbsDispSpanRoot a:link{
text-decoration: none !important;
}

.DarkTheme.LiBreadCrumbsDispSpanRoot a:visited{
  text-decoration: none !important;
  color: var(--white);
}

.DarkTheme.LiBreadCrumbsDispSpanRoot a:hover{
  text-decoration: none !important;
  color: var(--white);
}

.LightTheme.LiBreadCrumbsArrow {
background: url("../../images/libreadcrumbs/LightTheme/arrow.svg") no-repeat;

width: 0px;
height: 0px;
padding: 5px 5px 5px 5px;
border-style: none;
/* border: 1px solid red !important; */
}

.LightTheme.LiBreadCrumbsUsr {
 /* background: url("../images/DarkTheme/user_management.svg") no-repeat; */

 width: 20px;
 height: 20px;
 margin-left: 12px;
 margin-right: 12px;
 /* padding: 8px 8px 8px 8px; */
 border-style: none;
 /* border: 1px solid red !important; */
 /* line-height: 2 !important; */
 vertical-align: middle !important;
}

.LightTheme.LiBreadCrumbsDispSpan a {
  color: var(--black);
  font-weight: bold;
}

.LightTheme.LiBreadCrumbsDispSpan a:link{
  text-decoration: none !important;
}

.LightTheme.LiBreadCrumbsDispSpan a:visited{
  text-decoration: none !important;
  color: var(--black);
}

.LightTheme.LiBreadCrumbsDispSpan a:hover{
  text-decoration: none !important;
  color: var(--black);
}


.LightTheme.LiBreadCrumbsDispSpanRoot a {
  color: var(--black);
  font-weight: bold;
  font-size: 18px;
}

.LightTheme.LiBreadCrumbsDispSpanRoot a:link{
  text-decoration: none !important;
}

.LightTheme.LiBreadCrumbsDispSpanRoot a:visited{
  text-decoration: none !important;
  color: var(--black);
}

.LightTheme.LiBreadCrumbsDispSpanRoot a:hover{
  text-decoration: none !important;
  color: var(--black);
}