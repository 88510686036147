.moduleSettingshorizontalmenuline {
    border-bottom-style: solid;
    border-bottom-width: thin !important;
    border-color: #818181;
    display: block;
    /* margin-left: 10px ;
    margin-right: 10px; */
    margin-top: 15px;
}

.moduleSettingsclosebtn, .moduleSettingscontainerClosebtn, .moduleSettingscontainerPinbtn, .moduleSettingscontainerUnPinbtn {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    right: 10px;
    margin-left: 50px;
    cursor: pointer;
}

.moduleSettingscontainerClosebtn {
    top: 25px !important;
}

.moduleSettingscontainerPinbtn, .moduleSettingscontainerUnPinbtn {
    right: 40px;
    width: 20px !important;
    top: 23px !important;
    /* height: 15px !important; */
}

.moduleSettingskpiMenuBtn {
    font-size: 30px;
    cursor: pointer;
    float: right;
    position: fixed;
    right: 0;
    z-index: 1;
    top: 110px;
}

.moduleSettingskpiheadingDiv {
    padding-top: 10px;
    /* position: fixed; */
}

.moduleSettingskpiIcon {
    width: 25px;
    height: 25px;
    padding: 10px;
    background-color: #282b30;
}


.DarkTheme.moduleSettingssidenav, .DarkTheme.moduleSettingskpiMenuHeadingIcon, .DarkTheme.moduleSettingskpiMenu, .DarkTheme.moduleSettingskpiIcon {
    background-color: var(--darktheme-black-variant3) !important;
    color: white !important;
}
.LightTheme.moduleSettingssidenav, .LightTheme.moduleSettingskpiMenuHeadingIcon, .LightTheme.moduleSettingskpiMenu, .LightTheme.moduleSettingskpiIcon {
    background-color: #cacbcd !important;
    color: black !important;
}

.moduleSettingssidenav {
    height: calc(100vh - 118px) !important;
    /* height: 100%; */
    width: 0;
    position: fixed;
    z-index: 1;
    top: 105px;
    right: 0;
    background-color: #282b30;
    /* overflow-x: hidden; */
    /* overflow-y: auto !important; */
    transition: .5s;
    padding-top: 10px;
    overflow: hidden;
    /* box-shadow: 2px 2px 2px #282b30; */
}

.moduleSettingssidenav .moduleSettingskpiMenu {
    padding: 10px 8px 10px 64px;
    text-decoration: none;
    font-size: 15px;
    /* font-weight: normal; */
    color: #f1f1f1;
    display: block;
    transition: .1s;
    cursor: pointer;
}

.moduleSettingskpiMenuHeadingIcon {
    width: 22px;
    height: 22px;
    padding-right: 5px;
    padding-left: 22px;
    background-color: #282b30;
    vertical-align: middle !important;
}

.moduleSettingskpiMenuHeading {
    font-size: 24px !important;
    /* margin-left: 30px; */
    margin-top: -25px;
    padding-left: 64px;
    display: block;
    transition: 0.1s;
}

.moduleSettingskpiMenuHeading:hover, .moduleSettingskpiMenuHeadingIcon:hover {
    /* color:#818181 !important; */
    cursor: pointer;
}


@media screen and (max-height: 450px) {
    .moduleSettingssidenav {
        padding-top: 15px;
    }
    .moduleSettingssidenav .moduleSettingskpiMenu {
        font-size: 18px;
    }
}

