.TabMainContainer {
  font-size: 14px !important;
}

.DarkTheme.TabMainContainer {
  color: var(--white);
}

.TabHeader {
  display: flex;
  flex-direction: row;
}

.tab {
  float: left;
}

.TabComp {
  width: 100% !important;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
}

.TabLabel {
  font-size: 18px !important;
  font-weight: 600 !important;
  height: 25px;
  position: relative;
  padding: 3px;  
}

.DarkTheme .TabLabel{
  color: var(--darktheme-white-variant3);
  border-bottom: 2px solid  var(--darktheme-white-variant3);
  opacity: 1;
}

.LightTheme .TabLabel{
  border-bottom: 2px solid var(--lighttheme-black-variant2);
}

div.TabLabelInner > span{
  pointer-events: none;
}

.TabLabelSelected {
  font-size:18px !important;
  font-weight:600 !important;
  height: 24px;
  /* margin-right: 3%;  */
  position: relative;
  padding: 3px; 
}

.LightTheme.TabLabelSelected{
  border-bottom: 4px solid var(--tufts-blue) !important;
}

.DarkTheme.TabLabelSelected{
  color: var(--white);
  border-bottom: 4px solid var(--tufts-blue) !important; 
}

.TabMainContent{
  display: none;
}

.TabLabel:hover  .tooltiptext {
  visibility: visible;
}

.TabLabelSelected:hover  .tooltiptext {
  visibility: visible;
}



/* demo */

#my_container_div_tabs {
  height: 230px;
}
