.SnackbarContainer{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding : 5px 15px;
    z-index: 999999999;
}
.SnackbarUndo:hover{
    cursor: pointer;
}
.LightThemeSnackbar{
    background-color: var(--black);
    color: var(--white);
}
.DarkThemeSnackbar{
    background-color:var(--white);
    color: var(--black);
}
.TOP_RIGHT{
    top: 2%;
    right: 2%;
}
.TOP_LEFT{
    top: 2%;
    left: 2%;
}
.TOP_CENTER{
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0%);
}
.CENTER_RIGHT{
    top: 50%;
    right: 2%;
}
.CENTER_LEFT{
    top: 50%;
    left: 2%;
}
.CENTER_CENTER{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.BOTTOM_LEFT{
    bottom: 2%;
    left: 2%;
}
.BOTTOM_CENTER{
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, 0%);
}
.BOTTOM_RIGHT{
    bottom: 2%;
    right : 2%;
}