.LiCalendarGrid_calendarContainer {
	/* overflow: hidden; */
	max-width: 1200px;
	/* border: 1px solid red;  */
}

.LightTheme.LiCalendarGrid_calendarContainer {
	background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiCalendarGrid_calendarContainer {
	background-color: var(--darktheme-black-variant3);
}

.LiCalendarGrid_calendarHeader {
	height: 40px;
	/* line-height: 40px; */

	padding-left: 10px;
	padding-right: 10px;

	user-select: none;

	display: flex;
	align-items: center;
	vertical-align: middle;
	
	/* border: 1px solid red; */
}

.LightTheme.LiCalendarGrid_calendarHeader {
	background-color: var(--lighttheme-black-variant5);
}

.DarkTheme.LiCalendarGrid_calendarHeader {
	background-color: var(--darktheme-black-variant3);
}


.LiCalendarGrid_LeftArea {
	/* flex: 2; */
	width: 250px;

	display: flex;
	align-items: center;

	/* border: 1px solid green; */
}

.LiCalendarGrid_RightArea {
	/* width: 60px; */
	flex: 1;
	/* border: 1px solid yellow; */
}


.LiCalendarGrid_calendarIcon {
	/* width: 25px; */
	display: inline-block;

	/* border: 1px solid cyan; */
}

.LiCalendarGrid_calendarImgMain {
	vertical-align: middle;
	width: 24px;
	
	/* height: 20px; */
	/* border: 1px solid orange; */
}

.LiCalendarGrid_monthName {
	font-size: 16px;
	font-weight: bold;
	padding: 0px 6px;
	margin-left: 4px;

	min-width: 85px;
	display: inline-block;
	
	/* border: 1px solid blue; */
}

.LiCalendarGrid_prevMonth, .LiCalendarGrid_nextMonth {
	width: 20px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;

	cursor: pointer;

	/* border: 1px solid violet; */
}

.LiCalendarGrid_calendarImgNav {
	vertical-align: middle;
	width: 8px;
	/* padding: 0px 2px; */
	/* height: 20px; */
	/* border: 1px solid orange; */
}


.LiCalendarGrid_listView, .LiCalendarGrid_gridView {
	cursor: pointer;
	margin-top: 7px;
	margin-left: 6px;
	float: right;
	/* border: 1px solid blue; */
}

.LiCalendarGrid_calendar {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;

	display: grid;
	/* width: 100%; */
	grid-template-columns: repeat(7, minmax(120px, 1fr));
	grid-template-rows: 40px;
	grid-auto-rows: 120px;
	/* margin: 5px; */

	/* border: 1px solid blue; */
}

.LiCalendarGrid_dayName {
	font-size: 15px;
	font-weight: bold;
	/* color: #000000;
	background-color: #cccccc; */

	text-transform: uppercase;
	padding: 0 20px;
	
    height: 38px;
	line-height: 38px;
	
	pointer-events: none;
	user-select: none;
}

.LightTheme.LiCalendarGrid_dayName {
	color: #000000;
	background-color: var(--lighttheme-black-variant5);

	border-bottom: 1px solid var(--lighttheme-black-variant1);
	border-right: 1px solid var(--lighttheme-black-variant1);
}

.DarkTheme.LiCalendarGrid_dayName {
	color: var(--white);
	background-color: var(--darktheme-black-variant3);

	border-bottom: 1px solid var(--darktheme-white-variant1);
	border-right: 1px solid var(--darktheme-white-variant1);

	/* border-bottom: 1px solid var(--lighttheme-black-variant5);
	border-right: 1px solid var(--lighttheme-black-variant5); */
}

.LiCalendarGrid_day {
	/* z-index: 1; */

	letter-spacing: 1px;
	font-size: 16px;
	font-weight: bold;
	user-select: none;

	display: flex;
	flex-direction: column;

	pointer-events: auto;
	cursor: pointer;
}

.LiCalendarGrid_dayMouseOverBorder {
	border: 1px solid green !important;
}

.LightTheme.LiCalendarGrid_day {
	color: #000000;
	background-color: var(--lighttheme-black-variant5);
	border-bottom: 1px solid var(--lighttheme-black-variant1);
	border-right: 1px solid var(--lighttheme-black-variant1);
	
	border-left: 1px solid transparent;
    border-top: 1px solid transparent;
}

.DarkTheme.LiCalendarGrid_day {
	color: var(--white);
	background-color: var(--darktheme-black-variant3);
	border-bottom: 1px solid var(--darktheme-white-variant1);
	border-right: 1px solid var(--darktheme-white-variant1);

	border-left: 1px solid transparent;
    border-top: 1px solid transparent;
}

.LiCalendarGrid_dayRight {
	display: inline-block;

	/* display: flex; */
	/* flex-direction: row; */
	/* align-items: right; */

	text-align: right;

	font-size: 12px;
	font-weight: normal;

	/* border: 1px solid green; */
}

.LiCalendarGrid_dayRight2 {
	display: flex; 
	flex-direction: column;

	/* align-items: center; */
	font-size: 12px;
	font-weight: normal;

	/* border: 1px solid purple; */
}

.LiCalendarGrid_day > div {
	/* Leave size of the container according to content */
	/* flex: 0 0 auto; */

	/* border: 1px solid green; */
}

.LiCalendarGrid_day > div:first-child {
	text-align: left;
	padding: 6px 10px;
	padding-bottom: 1px;
	
	/* the following shifts the infos and errors to the bottom of the cell*/
	margin-bottom: auto;

	/* border: 1px solid yellow; */
}

.LiCalendarGrid_dayDisabled {
	color: #9a9ea1 !important;
}

.LiCalendarGrid_today {
	background-color: #c7c7c7;
}

.LightTheme.LiCalendarGrid_today {
	border: 1px solid var(--tufts-blue) !important;
}

.DarkTheme.LiCalendarGrid_today {
	border: 1px solid var(--darktheme-tufts-blue) !important;
}

.LiCalendarGrid_day:nth-of-type(n + 1):nth-of-type(-n + 7) {
	grid-row: 1;
}

.LiCalendarGrid_day:nth-of-type(n + 8):nth-of-type(-n + 14) {
	grid-row: 2;
}

.LiCalendarGrid_day:nth-of-type(n + 15):nth-of-type(-n + 21) {
	grid-row: 3;
}

.LiCalendarGrid_day:nth-of-type(n + 22):nth-of-type(-n + 28) {
	grid-row: 4;
}

.LiCalendarGrid_day:nth-of-type(n + 29):nth-of-type(-n + 35) {
	grid-row: 5;
}

.LiCalendarGrid_day:nth-of-type(n + 36):nth-of-type(-n + 42) {
	grid-row: 6;
}

.LiCalendarGrid_day:nth-last-of-type(n+0):nth-last-of-type(-n + 7) {
	border-bottom: none;
}

.LiCalendarGrid_day:nth-of-type(7n + 7) {
	border-right: none;
}

.LiCalendarGrid_dayName:nth-of-type(7n + 7) {
	border-right: none;
}

.LiCalendarGrid_day:nth-of-type(7n + 1) {
	grid-column: 1/1;
}

.LiCalendarGrid_day:nth-of-type(7n + 2) {
	grid-column: 2/2;
}

.LiCalendarGrid_day:nth-of-type(7n + 3) {
	grid-column: 3/3;
}

.LiCalendarGrid_day:nth-of-type(7n + 4) {
	grid-column: 4/4;
}

.LiCalendarGrid_day:nth-of-type(7n + 5) {
	grid-column: 5/5;
}

.LiCalendarGrid_day:nth-of-type(7n + 6) {
	grid-column: 6/6;
}
.LiCalendarGrid_day:nth-of-type(7n + 7) {
	grid-column: 7/7;
}


.LiCalendarGrid_infoBar {
	/* background-color: #bbbcbe; */
	/* color: #000000; */
	margin-top: 1px;
	margin-left: 1px;
	margin-right: 1px;

	padding: 2px 2px;

	font-weight: normal;
	font-size: 10px;
}

.LightTheme.LiCalendarGrid_infoBar {
	background-color:rgba(0, 0, 0, 0.38);
	color: var(--white);
}

.DarkTheme.LiCalendarGrid_infoBar {
	background-color:rgba(0, 0, 0, 0.38);
	color: var(--white);
}

.LiCalendarGrid_circle {
	/* display: inline-block; */
	display: flex;
	float: right;

	justify-content: center;
	align-items: center;

	height: 25px;
	margin: 0 1px;

	/* border-radius: 50%;
	width: 25px; */

	/* NEW DESIGN BADGE */
	border-radius: 3px;
	padding: 0 6px;
	/* NEW DESIGN BADGE */
}

.LightTheme.LiCalendarGrid_circle {
	color: var(--white);
}

.DarkTheme.LiCalendarGrid_circle {
	color: var(--darktheme-bunker);
}

.LiCalendarGrid_circle_BLACK {
	background-color: #000207;
}

.LiCalendarGrid_circle_GREEN {
	background-color: #348401;
}

.LiCalendarGrid_circle_YELLOW {
	background-color: #da9c55;
}

.LiCalendarGrid_circle_RED {
	background-color: #c70623;
}


.LiCalendarGrid_taskType_BLACK {
	background-color: #000207;
}

.LiCalendarGrid_taskType_GREEN {
	border-left-color: #348401;
}

.LiCalendarGrid_taskType_YELLOW {
	border-left-color: #da9c55;
}

.LiCalendarGrid_taskType_RED {
	border-left-color: #c70623;
}


.LiCalendarGrid_daySelected {
}

.LightTheme.LiCalendarGrid_daySelected {
	background-color: var(--tufts-blue) !important;
}

.DarkTheme.LiCalendarGrid_daySelected {
	background-color: var(--darktheme-tufts-blue) !important;
}


.LiCalendarGrid_dayDragOver {
}

.LightTheme.LiCalendarGrid_dayDragOver {
	background-color: var(--lighttheme-claret-violet-variant1) !important;
}

.DarkTheme.LiCalendarGrid_dayDragOver {
	background-color: var(--darktheme-tufts-blue-variant1) !important;
}
