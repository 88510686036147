.LiFileUpload_Boxarea {
  width: 100% !important;
  /* height: 250px !important; */
  margin-top: 5px;
  /* margin-right: 50px; */

  overflow: auto;
  text-align: center;
}

.LightTheme.LiFileUpload_Boxarea {
  border: 1px dashed var(--lighttheme-black-variant1);
}

.DarkTheme.LiFileUpload_Boxarea {
  border: 1px dashed var(--darktheme-white-variant1);
}

/* .LiFileUpload_Outer {
  display: flex;
  flex-direction: row;
  align-items: left;
  border: 1px solid blue;
} */

.LiFileUpload_Uploader {
  width: 100%;
  height: 100%;
  /* border: 1px solid violet; */
}

.LiFileUpload_Preview {
  /* display: flex; */
  /* flex-direction: column; */
  /* border: 1px solid green; */
  width: 100%;
  /* height: 100%; */

  /* margin-left: 100px; */
  margin-top: 30px;
  /* float: right; */
}

.LiFileUpload_Preview2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  /* margin-left: 10px; */
  margin-top: 30px;
  /* border: 1px solid orangered; */
}

.LiFileUpload_PreviewInner {
  display: flex;
  flex-direction: row;
  /* height: 25px; */
  /* border: 1px solid yellow; */
}

.LiFileUpload_PreviewLabel {
  margin-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.LiFileUpload_PreviewEmbed {
  width: 95%;
  height: 95%;
  /* border: 1px solid red; */
}


.LiFileUpload_Boxarea_Single {
width: 100%;
overflow: auto;

text-align: center;
margin-right: 800px;
margin-top: 5px;

/* border: 1px solid blue; */
}

.LightTheme.LiFileUpload_Boxarea_Single {
  border: 1px dashed var(--lighttheme-black-variant1);
}

.DarkTheme.LiFileUpload_Boxarea_Single {
  border: 1px dashed var(--darktheme-white-variant1);
}

.LiFileUpload_Main {
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.LiFileUpload_UploadButton {
  position: relative;
  width: 103px;
  padding: 2px;
  /* margin-top: 15px; */
  margin: 5px auto;

  border-radius: 5px;

  cursor: pointer !important;
  text-align: center;
  font-weight: bold;
  transition: background 0.8s;
  background-position: center;
  background-size: 100%;
  /* border: 1px solid red; */
}

.LiFileUpload_UploadButton:active {
  transition: background 0s;
}

.LightTheme.LiFileUpload_UploadButton {
  background-color: var(--lighttheme-black-variant1) !important;
  border: 1px solid  var(--lighttheme-black-variant1) !important;
  color: var(--white);
}

.LightTheme.LiFileUpload_UploadButton {
  background-color: var(--lighttheme-black-variant1) !important;
  border: 1px solid  var(--lighttheme-black-variant1) !important;
  color: var(--white);
}

.LightTheme.LiFileUpload_UploadButton:hover {
  background: var(--lighttheme-placebo) radial-gradient(circle, transparent 1%, var(--tufts-blue) 1%) center/15000% !important;
  color: var(--black);
  cursor: pointer;
}

.DarkTheme.LiFileUpload_UploadButton:hover {
  background: var(--beautiful-blue) radial-gradient(circle, transparent 1%, var(--darktheme-tufts-blue) 1%) center/15000% !important;
  color: var(--white) !important;
}

.LightTheme.LiFileUpload_UploadButton:active {
  color: var(--white) !important;
  background-color: var(--tufts-blue) !important;
}

.LiFileUpload_DragText {
  font-size: 15px;
  font-weight: bold;
  margin: 1%;
}

.LightTheme.LiFileUpload_DragText {
  color: var(--lighttheme-black-variant1);
}

.DarkTheme.LiFileUpload_DragText {
  color: var(--darktheme-white-variant3);
}


input[type="file"].LiFileUpload_Box__File {
  width: 118px;
  height: 35px;

  position: absolute;
  top: -5px; /* size of border */
  left: -5px; /* size of border */
  
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  /* border: 1px solid red; */
  opacity: 0; /* hide actual input */
  cursor: pointer !important;
}

.LiFileUpload_ErrorContainer {
  width: 100%;
  height: 80px;
  /* margin-right: 800px; */

  align-items: center !important;
  justify-content: center !important;
  
  /* background: #474848; */

  /* border: 1px solid blue; */
}

.LiFileUpload_ErrorMessage {
  height: 60px;
  margin-top: 2%;
  padding: 10px;

  background: var(--darktheme-white-variant5);
  
  text-align: center;
  align-items: center !important;
  justify-content: center !important;
}

.LightTheme.LiFileUpload_ErrorMessage {
  color: var(--white);
}

.LiFileUpload_DeleteImage {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  cursor: pointer;
}

.LiFileUpload_TextValue {
  overflow: hidden;
  max-width: 30ch;
}

.LiFileUpload_File {
  display: flex;
  height: 35px;
  margin-top: 3px;

  align-items: center;
  justify-content: center;
  cursor: default !important;
  
}

.LiFileUpload_File_With_Preview{
  cursor: pointer !important;
}

.LightTheme.LiFileUpload_File {
  background-color: #8f8f8f;
  color: black;
}

.DarkTheme.LiFileUpload_File {
  background-color: #1a1b1f;
  color: white;
}

.LiFileUpload_Text {
  display: flex;
  width: 300px;
  height: 100%;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.LiFileUpload_FileUpload {
  height: 125px;
  width: 125px;
  background-image: url("../../images/lifileupload/fileupload.svg");
  justify-content: center !important;
  /* margin-left: 140px; */
  margin: 0 auto;
}

.Preview {
  width: 35%;
  height: 30%;
  /* border: 1px solid yellow; */
}

.Image_Preview{
/* margin-top:-65px !important; */
/* margin-left:-50px !important; */
}


.twoColumnMainGridContainerFileUpload {
  display: grid;
  grid-template-columns: 75% auto;
  width: 45%;
  /* margin-left: 175px; */

  /* border: 1px solid red; */
  min-width: 400px !important;
}

.twoColumnMainGridContainerItemFileUpload {
  padding: 5px;
  vertical-align: middle;
  line-height: 1.5;
  padding-bottom: 5px;
  
  /* border: 1px solid violet; */
}

input[type=file]::-webkit-file-upload-button { 
  cursor: pointer; 
}
