/* The liButtonContainer */
.LiRadio_LabelClass {
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
}

.LiRadio_LabelSpan{
  color: var(--black) !important;
  line-height: 2 !important;
}

.LiRadio_LabelClass .LiRadio_SpanClass {
  position: relative;
  float: left;
  margin-right: 0px;
  width: 20px;
  height: 20px;
  border: 2px solid var(--black);
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
}

.LiRadio_LabelClass .LiRadio_SpanClass:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #3C454D;
  transform: scale(0);
  transition: all 0.2s ease;
  opacity: 0.25;
  pointer-events: none;
}

.LiRadio_LabelClass:hover .LiRadio_SpanClass:after {
  transform: scale(3.6);
  background: var(--tufts-blue);
}

.hidden:checked + .LiRadio_SpanClass {
  border-color: var(--tufts-blue);
  background-color: var(--tufts-blue);
}

.hidden:disabled + .LiRadio_SpanClass {
  border-color: var(--lighttheme-black-variant1) ;
  cursor: not-allowed;
}

.hidden:disabled + .LiRadio_SpanClass:after {
   background: var(--lighttheme-black-variant1);
   cursor: not-allowed;
}

.hidden:disabled + .LiRadio_SpanClass:after {
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}

.hidden:checked + .LiRadio_SpanClass:after {
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
  background: var(--white); /* inner round color*/
}

.hidden {
  display: none;
}



/* .DarkTheme */


.DarkTheme .LiRadio_LabelClass .LiRadio_SpanClass {
  border: 2px solid var(--white);
}

.DarkTheme .LiRadio_LabelSpan{
  color: var(--white) !important;
}

.DarkTheme .LiRadio_LabelClass .LiRadio_SpanClass:after {
  background: var(--white);
}

.DarkTheme .LiRadio_LabelClass:hover .LiRadio_SpanClass:after {
  background: var(--tufts-blue);
}

.DarkTheme .hidden:checked + .LiRadio_SpanClass {
  border-color: var(--tufts-blue);
  background-color: var(--tufts-blue);
}

.DarkTheme .hidden:disabled + .LiRadio_SpanClass {
  border-color: var(--darktheme-white-variant3);
}

.DarkTheme .hidden:disabled + .LiRadio_SpanClass:after {
   background: var(--darktheme-white-variant3);
}

.DarkTheme .hidden:checked + .LiRadio_SpanClass:after {
  /* background-color: var(--tufts-blue); */
  background: var(--white); /* inner round color*/
}

.DarkTheme .hidden {
  display: none;
}
