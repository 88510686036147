  
  .TooltipContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: var(--white); */
  }
  
  .Li_Tooltip_Three {
    border: 1px solid var(--black);
    position: absolute;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    color: var(--black);
    background-color: var(--white);
    max-width: 250px;
    min-width: 100px;
    opacity: 0;
    transition-property: opacity, transform;
    transform-origin: center center;
    text-align: center;
    display: inline-block;
    word-wrap: break-word;
    z-index: 9999999999;
  }
  
  .Li_Tooltip_Three--top {
    transform: translate(-50%, -15px) scale(0);
  }
  .Li_Tooltip_Three--bottom {
    transform: translate(-50%, 15px) scale(0);
  }
  .Li_Tooltip_Three--left {
    transform: translate(-15px, -50%) scale(0);
  }
  .Li_Tooltip_Three--right {
    transform: translate(15px, -50%) scale(0);
  }
  .Li_Tooltip_Three--top-left {
    transform: translate(0, -15px) scale(0);
  }
  .Li_Tooltip_Three--top-right {
    transform: translate(-100%, -15px) scale(0);
  }
  .Li_Tooltip_Three--bottom-left {
    transform: translate(0, 15px) scale(0);
  }
  .Li_Tooltip_Three--bottom-right {
    transform: translate(-100%, 15px) scale(0);
  }
  .Li_Tooltip_Three--left-top {
    transform: translate(-15px, 0) scale(0);
  }
  .Li_Tooltip_Three--left-bottom {
    transform: translate(-15px, -100%) scale(0);
  }
  .Li_Tooltip_Three--right-top {
    transform: translate(15px, 0) scale(0);
  }
  .Li_Tooltip_Three--right-bottom {
    transform: translate(15px, -100%) scale(0);
  }
  
  .Li_Tooltip_Three::after {
    border: solid transparent;
    /* border: 1px solid var(--darkTheme-borderColor); */
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(255,0,0,0);
    border-width: 6px;
  }
  
  .Li_Tooltip_Three--top::after {
    top: 100%;
    left: 50%;
    border-top-color:  rgba(255,0,0,0);;
    margin-left: -6px;
    /* border: 6px solid var(--darkTheme-borderColor); */

  }
  .Li_Tooltip_Three--bottom::after {
    bottom: 100%;
    left: 50%;
    border-bottom-color:  rgba(255,0,0,0);;
    margin-left: -6px;
  }
  .Li_Tooltip_Three--left::after {
    top: 50%;
    left: 100%;
    border-left-color:  rgba(255,0,0,0);;
    margin-top: -6px;
  }
  .Li_Tooltip_Three--right::after {
    top: 50%;
    right: 100%;
    border-right-color:  rgba(255,0,0,0);;
    margin-top: -6px;
  }
  .Li_Tooltip_Three--top-left::after {
    top: 100%;
    left: 0%;
    border-top-color:  rgba(255,0,0,0);;
    margin-left: 10px
  }
  .Li_Tooltip_Three--top-right::after {
    top: 100%;
    right: 0%;
    border-top-color:  rgba(255,0,0,0);;
    margin-right: 10px
  }
  .Li_Tooltip_Three--bottom-left::after {
    bottom: 100%;
    left: 0%;
    border-bottom-color:  rgba(255,0,0,0);;
    margin-left: 10px
  }
  .Li_Tooltip_Three--bottom-right::after {
    bottom: 100%;
    right: 0%;
    border-bottom-color:  rgba(255,0,0,0);;
    margin-right: 10px
  }
  .Li_Tooltip_Three--left-top::after {
    top: 10px;
    left: 100%;
    border-left-color:  rgba(255,0,0,0);;
  }
  .Li_Tooltip_Three--left-bottom::after {
    bottom: 10px;
    left: 100%;
    border-left-color:  rgba(255,0,0,0);;
  }
  .Li_Tooltip_Three--right-top::after {
    top: 10px;
    right: 100%;
    border-right-color:  rgba(255,0,0,0);;
  }
  .Li_Tooltip_Three--right-bottom::after {
    bottom: 10px;
    right: 100%;
    border-right-color:  rgba(255,0,0,0);;
  }
  
