.LiSearchBoxMain {
  /* width: 240px; */
  width: 100%;
  height: 30px;
  display: block !important;

  min-width: 50px !important;
  /* border: 1px solid red; */
}

.LiSearchBoxMainDisabled {
  /* width: 240px; */
  width: 100%;
  height: 30px;
  cursor: not-allowed;
}

.LiSearchBoxContainer {
  position: relative !important;
  /* display: block; */
  float: left;

  width: calc(100% - 24px - 24px) !important;
  height: inherit;

  /* border: 1px solid orange; */
}

.LiSearchBoxContainerWithOutIcon{
  position: relative !important;
  /* display: block; */
  float: left;

  width: calc(100% - 24px) !important;
  height: inherit;

  /* border: 1px solid orange; */
}

.LiSearchBoxLeftIcon {
  width: 14px;
  height: 14px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: block;
  float: left;

  margin-top: 8px;
  margin-left: 7px;

  /* border: 1px solid green; */
}

.LiSearchBox {
  width: 100% !important;
  height: inherit;

  background-color: inherit;
  border: none;
  font-weight: 500;
  box-sizing: border-box;
  outline: none !important;

  display: block !important;

  padding-left: 6px; 

  /* border: 1px solid blue; */
}

.LiSearchBoxFloatingLabel {
  position: absolute !important;
  /* width: 10px !important; */

  left: 7px !important;
  /* top: -24px !important; */
  top: 8px !important;

  font-weight: normal !important;

  pointer-events: none;
  transition: 0.2s ease all;
  user-select: none;
  cursor: text !important;

  color: var(--darktheme-white-variant1) !important;
}

.LightTheme.LiSearchBoxFloatingLabel {
  /* color: var(--darktheme-white-variant1) !important; */
}

.DarkTheme.LiSearchBoxFloatingLabel {
  color: var(--darktheme-white-variant1) !important;
}

.LiSearchBox:disabled {
  cursor: not-allowed;
}

.SearchIcon {
  /* background-size: cover; */
  width: 14px;
  height: 14px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  /* display: inline-block; */
  /* position: relative; */

  display: block;
  float: right;

  margin-top: 8px;
  margin-right: 7px;
  cursor: pointer;

  /* border: 1px solid red; */
}

.LightTheme.backgroundContainer {
  background-color: #E6E7E6 !important;
}

.LightTheme.LiSearchBoxMain {
  background-color: var(--lighttheme-black-variant5);
  /* border: 1px  solid var(--lighttheme-black-variant5); */
  border: 1px solid var(--lighttheme-black-variant1);
}

.LightTheme.LiSearchBoxMainDisabled {
  background-color: #dcdddc;
  border: 1px solid var(--lighttheme-black-variant1);
}

.LightTheme.LiSearchBoxMainDisabled:hover {
  border: 1px solid var(--black) !important;
}

.LightTheme.LiSearchBoxMain:hover {
  border: 1px solid var(--black) !important;
}

.LightTheme.LiSearchBox {
  color: var(--lighttheme-black-variant1) !important;
}

.LightTheme.SearchIcon.Enabled {
  background: url("../../images/lisearchbox/LightTheme/search_active_light_theme.svg");
}

.LightTheme.SearchIcon.Disabled {
  cursor: not-allowed;
  background: url("../../images/lisearchbox/LightTheme/search_inactive_light_theme.svg");
}

.LightTheme.SearchIcon.ClearIcon {
  width: 10px;
  height: 10px;
  /* margin-top: -20px; */
  background: url("../../images/lisearchbox/LightTheme/close_light_theme.svg");
}

.LightTheme.LiSearchBoxMain:focus-within {
  border: 1px solid var(--tufts-blue); 
}

.DarkTheme.backgroundContainer {
  background-color: #E6E7E6 !important;
}

.DarkTheme.LiSearchBoxMain {
  background-color: var(--darktheme-black-variant3);
  /* border: 1px  solid var(--darkTheme-bgColor); */
  border: 1px solid var(--darktheme-white-variant1);
}

.DarkTheme.LiSearchBoxMainDisabled {
  background-color: var(--darktheme-black-variant3);
  border: 1px solid var(--darktheme-white-variant3);
}

.DarkTheme.LiSearchBoxMainDisabled:hover {
  border: 1px solid var(--white) !important;
}

.DarkTheme.LiSearchBoxMain:hover {
  border: 1px solid var(--white) !important;
}

.DarkTheme.LiSearchBox {
  color: var(--darktheme-white-variant1) !important;
}

.DarkTheme.SearchIcon.Enabled {
  background: url("../../images/lisearchbox/DarkTheme/search_active_dark_theme.svg");
}

.DarkTheme.SearchIcon.Disabled {
  cursor: not-allowed;
  background: url("../../images/lisearchbox/DarkTheme/search_inactive_dark_theme.svg");
}

.DarkTheme.SearchIcon.ClearIcon {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  background: url("../../images/lisearchbox/DarkTheme/close_dark_theme.svg");
}

.DarkTheme.LiSearchBoxMain:focus-within {
  border: 1px solid var(--darktheme-tufts-blue) !important; 
}