.LightTheme.LiImageMenu_DispImage{
  width: 90px !important;
  cursor: pointer;

}

.DarkTheme.LiImageMenu_DispImage{
  width: 90px !important; 
  cursor: pointer;
}

.LiImageMenu_ImageTitle{
  line-height:20px;
  text-align: center;
  font: 'regular 22px/26px Roboto';
}

.LiImageMenu_ImageDescription{
  line-height: 10px;
  text-align: center;
  color: #969696;
  font-size: 8pt;
  font: 'regular 22px/26px Roboto';
  text-decoration: none !important;
}

.LiImageMenuspanMain{
  display: inline-block;
}


.DarkTheme.LiImageMenuLink{
  text-decoration: none !important;
  color: var(--white) !important;
}

.DarkTheme.LiImageMenuLink:link{
  text-decoration: none !important;
}

.DarkTheme.LiImageMenuLink:visited{
  text-decoration: none !important;
  color: var(--white);
}

.LightTheme.LiImageMenuLink{
  text-decoration: none !important;
  color: var(--black);
}


.LightTheme.LiImageMenuLink:link{
  text-decoration: none !important;
}

.LightTheme.LiImageMenuLink:visited{
  text-decoration: none !important;
  color: var(--black);
}
