.LiExpansionPanel_container {
    margin-bottom: 5px;
    width:100%;
}

.LightTheme.LiExpansionPanel_collapsible {
    background-color: var(--lighttheme-black-variant5);
    color: var(--black);
    border: 0.5px solid var(--lighttheme-black-variant1);
}

.DarkTheme.LiExpansionPanel_collapsible {
    background-color: var(--darktheme-black-variant3);
    color: white;
    border: 0.5px solid var(--darktheme-white-variant1);
}

.LiExpansionPanel_collapsible {
    cursor: pointer;
    padding: 8px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* border: 0.5px solid; */
}

.LiExpansionPanel_insidePanelOld {
    float: left;
    width: 35px;
    margin-left: 5px;
    margin-top: -23px;
}

.LiExpansionPanel_insidePanel {
    position: relative;
    /* float: left; */
    /* width: 100%; */
    /* text-align: center; */
    /* margin-top: -11px; */
    /* margin-left: 20px; */    
}

.LiExpansionPanel_insidePanel table {
    /* border: .5px solid; */
    font-size: 0.8rem;
}

.LightThemeLiExpansionPanel_insidePanel th{
    text-decoration: none !important;
    font-weight: 300;
    font-size: 12px;
    padding-bottom: 10px;
    padding-right: 50px;
    color : var(--black);
}

.DarkThemeLiExpansionPanel_insidePanel th{
    /* border-bottom: .5px solid black; */
    text-decoration: none !important;
    font-weight: 300;
    font-size: 12px;
    padding-bottom: 10px;
    padding-right: 50px;
    color : #FFFFFF8A;
}
.LiExpansionPanel_insidePanel td{
    /* border-bottom: .5px solid black; */
    font-size: 14px;
}

.LiExpansionPanel_collapsible img {  
    display: inline-block;
    vertical-align: middle;
    background: transparent;
    padding-left: 5px;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    pointer-events: none;
}

span.LiExpansionPanel_header{
    padding-left: 15px;
    width : auto;    
}

button.LiExpansionPanel_collapsible.LiExpansionPanel_collapsiblepanel1 > * {
    pointer-events: none;
}

.LightTheme.LiExpansionPanel_content {
    padding: 18px 18px;
    display: none;
    overflow: auto;
    transition: max-height 0.2s ease-out;
    background-color: var(--lighttheme-placebo);
    border: 0.5px solid var(--lighttheme-black-variant1);
    border-top: none;
}

.DarkTheme.LiExpansionPanel_content {
    padding: 18px 18px;
    display: none;
    overflow: auto;
    transition: max-height 0.2s ease-out;
    background-color: var(--darktheme-bunker);
    border: 0.5px solid var(--darktheme-white-variant4);
    border-top: none;
}

.LiExpansionPanel_header{
    pointer-events: none;
}

.expansionPanelHeightStyles{
    height : 80px;
    padding : 0px;
    /* pointer-events: none; */
}

.expansionPanelArrowStyles{
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events : none;
    margin-left: auto;
}
