.LiButton_ParentNode {
  padding: 5px !important;
}

.LiButton_DefaultClass {
  height: 32px;
  border: none;
  padding: 3px;
  font-size: 10.5px;
  min-width: 64px;
  line-height: 1.75px;
  border-radius: 3px;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;
  background-size: 100%;
  /* margin-top: 5px !important; */
  outline: none !important;
}

.LiButton_DisabledClass:hover {
  cursor: not-allowed;  
}

.LiButton_DisabledClass {
  height: 32px;
  border: none;
  padding: 2px 1px;
  font-size: 10.5px;
  min-width: 64px;
  line-height: 1.75px;
  border-radius: 3px;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;
  background-size: 100%;
  /* margin-top: 5px !important; */
  border: 1px solid #9a9b9b;
}

.LiButton_DefaultClass:active {
  transition: background 0s;
}

.LightTheme.LiButton_DefaultClass {
  background-color: var(--lighttheme-black-variant1);
  color: var(--white);
}

.LightTheme.LiButton_DefaultClass:hover {
  background: var(--darktheme-yale-blue) radial-gradient(circle, transparent 1%, var(--tufts-blue) 1%) center/15000%;
  color: var(--white);
}

.LightTheme.LiButton_DefaultClass:active {
  color: var(--white) !important;
  background-color: var(--tufts-blue);
}

.LightTheme.LiButton_DefaultClass:focus {
  color: var(--white) !important;
  background-color: var(--tufts-blue);
}

.LightTheme.LiButton_DefaultClass:disabled {
  background-color: var(--lighttheme-black-variant5);
  color: var(--lighttheme-black-variant1);
}

.LightTheme.LiButton_DisabledClass{
  background-color: var(--lighttheme-black-variant4) !important;
  color: var(--lighttheme-black-variant2) !important;
}

.DarkTheme.LiButton_DefaultClass {
  background-color: var(--darktheme-white-variant3);
  color: var(--white);
}

.DarkTheme.LiButton_DefaultClass:hover {
  background: var(--darktheme-yale-blue) radial-gradient(circle, transparent 1%, var(--tufts-blue) 1%) center/15000% !important;
  color: var(--white) !important;
}

.DarkTheme.LiButton_DefaultClass:active {
  color: var(--white) !important;
  background-color: var(--tufts-blue) !important;
}

.DarkTheme.LiButton_DefaultClass:focus {
  background-color: var(--tufts-blue) !important;
  color: var(--white) !important;
}

.DarkTheme.LiButton_DefaultClass:disabled {
  background-color: var(--darktheme-white-variant5) !important;
  color: var(--darktheme-white-variant3) !important;
}

.DarkTheme.LiButton_DisabledClass {
  background-color: var(--darktheme-white-variant5) !important;
  color: var(--darktheme-white-variant3) !important;
  border: 1px solid #5d6165;
}

.DarkTheme.LiButton_checkbox {
  width: 100%;
  padding:10px;
}

.buttonImgPadding {
  padding-right: 2px;
  padding-left: 2px;
}

.imageButon {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  border-radius: 0px !important;
}
