.mainBox{
    display: flex;
    flex-direction: row;
    /* margin-left: 2.5%;
    margin-top: 2.5%; */
    height : 96vh;
}
.boxOneLight{
    height : 100%;
    width : 15%;
    background-color: var(--lighttheme-black-variant1);
}
.boxOneDark{
    height : 100%;
    width : 15%;
    background-color: var(--darktheme-black-variant3)
}
.boxTwo{
    height : 100%;
    width : 100%
}