.headerContentLightBox{
    display: flex;
    justify-content: flex-start;
    background-color: var(--lighttheme-black-variant1);
    color : white;
    cursor : pointer;
}
.headerContentDarkBox{
    display: flex;
    justify-content: flex-start;
    background-color: var(--darktheme-black-variant3);
    color : white;
    cursor : pointer;
}
.headerContentLight{
    display : flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    width : 100%;
    background-color: var(--lighttheme-black-variant1);
    color : white;
}
.headerContentLight:hover{
    background-color: var(--darktheme-yale-blue);
}
.headerContentDark{
    display : flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    width : 100%;
    background-color: var(--darktheme-black-variant3);
    color : white;
}
.headerContentDark:hover{
    background-color: var(--darktheme-malibu);
}
.headerClickedLight{
    display : flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    width : 100%;
    background-color: var(--darktheme-tufts-blue);
    color : white;
}
.headerClickedDark{
    display : flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    width : 100%;
    background-color: var(--darktheme-tufts-blue);
    color : white;
}