.LiPreloader_outerDiv {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.50);
  z-index: 999999999 !important;
  /* border: 5px solid green; */
}

.LiPreloader_outerDiv.moduleLoading {
  top: 60px;
  left: 16%;
}

.LiPreloader_main.moduleLoading {
  left: 38%;
  top: 45%;
}

.LiPreloader_main {
  width: 7%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 35;
  transform: translate(-50%, -50%);
  user-select: none;
}

.LiPreloader_closeIcon {
  width: 2%;
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 35;
  cursor: pointer;
}
