body {
    width: 100%;
    min-height: 700px;
}

a {
    cursor: pointer;
    text-decoration: underline;
}

.ui-dialog-overlay {
    background: #aaaaaa;
    opacity: .6;
    filter: Alpha(Opacity=30);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.ui-dialog {
    position: fixed !important;
    top: 40% !important;
    left: 45%;
    display: inline-block;
    right: 20% !important;
    /* outline: 0 none; */
    padding: 0 !important;
    z-index: 9999101;
    /* background-color: white; */
    color: black;
    border: 1px solid #f6f6f6;
    width: 80%;
    height: 60%;
    /* margin-left: 10%; */
     min-width: 600px !important;
    min-height: 400px !important;
    /* transform: translate(-50%, -50%); */ 
}

.ui-dialog.maximized {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.ui-dialog.minimized {
    position: fixed;
    bottom: 0;
    right: 0;
}

.ui-dialog .ui-dialog-titlebar {
    position: relative;
    font-size: 1em;
    /* border-radius: 3px; */
    padding: 0.5em;
    height: 35px;
    border-bottom: 1px solid #f6f6f6;
}

.ui-dialog.react-draggable .ui-dialog-titlebar {
    cursor: move;
}

.ui-dialog .ui-dialog-titlebar .action-items {
    float: right;
    position: relative;
    margin-top: 3px;
}

.ui-dialog .ui-dialog-titlebar .title {
    float: left;
    margin-right: .5em;
    margin-top: 3px;
    /* font: Medium 12px/14px Roboto !important;  */
    /* font-size: 1.5em; */
}

.icon {
    width: 24px;
    height: 24px;
    display: block;
    float: left;
    margin: 5px;
    cursor: pointer;
    background-size: cover;
}

.DarkTheme .icon.icon-close {
    width: 20px;
    height: 20px;
    /* background-image: url("./img/close_white.gif") ; */
    background-image: url("../../images/react-dialog/close_dark_theme.svg")  !important;
}

.LightTheme .icon.icon-close {
    width: 20px;
    height: 20px;
    /* background-image: url("./img/close_white.gif") ; */
    background-image: url("../../images/react-dialog/close_light_theme.svg")  !important;
}

.icon.icon-minimize {
    background-image: url("../../images/react-dialog/minimize.png");
}

.icon.icon-maximize {
    background-image: url("../../images/react-dialog/maximize.png");
}

.icon.icon-restore {
    background-image: url("../../images/react-dialog/restore.png");
}

.ui-dialog .ui-dialog-content {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    overflow: auto;
    position: relative;
    padding: 0.5em 0.5em 0 0.5em;
    height: 85%;
}

.LightTheme .ui-dialog .ui-dialog-content {
    /* height: 85%; */
    background-color:#E6E7E6 !important;
}

.DarkTheme .ui-dialog .ui-dialog-content {
    /* height: 79%; */
    background-color: #30373d !important;
}

.DarkTheme .ui-dialog .ui-dialog-titlebar {
    border-bottom: 1px solid black !important;
    background-color: #282C30 !important;
    color: white !important;
    opacity: 1; 
}


.ui-dialog .ui-dialog-buttonpane {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: right;
    border-width: 1px 0 0 0;
    /* border-top: 1px solid #f6f6f6; */
    height: 35px !important;
    min-height: 50px !important;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    padding: 0 0.5em;  
}

.ui-dialog .react-resizable-handle {
    position: absolute;

    /* width: 20px;
    height: 20px;
    bottom: 0;
    right: 0; */

    height: 34px;
    width: 34px;
    
    bottom: 0;
    right: 0;

    margin-right: -14px;
    margin-bottom: -14px;

    /* background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
    /* background-position: bottom right; */
    /* padding: 0 3px 3px 0; */
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.LightTheme .ui-dialog .react-resizable-handle {
    background-image: url('../../images/react-dialog/light_drag.svg') !important;
    /* height: 20px;
    width: 20px;
    margin: 0px; */
}

.DarkTheme .ui-dialog .react-resizable-handle {
    background-image: url('../../images/react-dialog/dark_drag.svg') !important;
    /* height: 20px;
    width: 20px;
    margin: 0px;     */
}

/* .ui-dialog .ui-dialog-buttonpane button {
	margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: #404549;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    color:white;
} */

.dialog-content {
    /* margin-left: 105px; */
    width: 100%;
    margin: 0 auto;
    /* font: Medium 18px/22px Roboto; */
    /* border: 1px solid red; */
}

.serverResponse .ui-dialog {
    height: 100px;
    min-height: 100px !important;
}

.serverResponse .ui-dialog-content {
    height: calc(100% - 67px) !important;
 }

.ui-dialog-buttonresponse {
    /* margin-left: 250px;
    margin-top: 30px; */
    text-align: center;
}

/* .DarkTheme .ui-dialog-buttonresponse button{
    margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: var(--DarkTheme_backgroundColorEnabled1) !important;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #404549 !important;
    border-radius: 2px;
    color: white; */
    /* font-size: 14px; */
    /* font: Medium 12px/14px Roboto;  */
/* } */
/* 
.DarkTheme .ui-dialog-buttonresponse button:hover{

    background-color: #4080C1 !important;
    border: 1px solid #4080C1 !important;
    color:white !important;
}

.DarkTheme .ui-dialog-buttonresponse button:focus{

    background-color: #4080C1;
    border: 1px solid #4080C1;
} */

/* 
.LightTheme .ui-dialog-buttonresponse button{
    margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: var(--LightTheme_backgroundColorEnabled2) !important;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #D3D3D3 !important;
    border-radius: 2px;
    color:var(--LightTheme_textColorEnabled);  
}

.LightTheme .ui-dialog-buttonresponse button:hover{
    background-color: #881B4C !important;
    border: 1px solid #881B4C !important;
    color:white !important;  
}

.DarkTheme .ui-dialog .ui-dialog-buttonpane button {
	margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: var(--DarkTheme_backgroundColorEnabled1) !important;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #404549 !important;
    border-radius: 2px;
    color:#666868;
    /* font-size: 14px; */
    /* font: Medium 12px/14px Roboto; */
    /* margin-top:-100px; */
   
/* } */

.ui-dialog-buttonset span {
    margin-left: 5px;
}

.ui-dialog-buttonresponse span {
    margin-left: 5px;
}

.ui-dialog-titlebar .title {
    font-size: 20px !important;
    /* font: Medium  Roboto !important; */
}

.ui-dialog-titlebar {
    background-color: #282C30 !important;
    color: white !important;
    opacity:1;
}

/* CSS for Bhoomika starts */
@media screen and (min-width:1025px) {
    .ui-dialog {
        /* max-width: 80% !important; */
        /* left: 35% !important; */
    }   
}
@media screen and (max-width:620px){
    .ui-dialog {
        min-height: 634px !important;
        min-width: 450px !important;
        z-index: 99999999;
    }
}
/* CSS for Bhoomika ends */
@media screen and (min-width:769px) and (max-width:1025px){
    .ui-dialog {
        height: 600px !important;
        width: 80% !important;
        left: 35% !important;
    }   
}
@media screen and (max-width:768px){
    .ui-dialog {
        height: 700px !important;
        width: 80% !important;
        z-index: 99999999;
    }
}
@media screen and (max-width:420px){
    .ui-dialog {
        min-height: 634px !important;
        min-width: 361px !important;
        z-index: 99999999;
    }
}

.DarkTheme .ui-dialog {
    border: 1px solid #30373d !important;
    color: white;
    background-color: #30373d;
}
.LightTheme .ui-dialog {
    border: 1px solid #E6E7E6 !important;
    /* color: white; */
    background-color: #E6E7E6;
}

.DarkTheme .ui-dialog .ui-dialog-titlebar {
    border-bottom: 1px solid black !important;
    background-color: #282C30 !important;
    color: white !important;
    opacity:1;
}

.LightTheme .ui-dialog .ui-dialog-titlebar {
    border: 1px solid #D3D3D3!important;
    background-color: #D3D3D3!important;
    color: black !important;
    opacity:1;
}

.DarkTheme .ui-dialog .ui-dialog-buttonpanefooter {
    background-color: #30373d ;
    /* display: none; */
}

.DarkTheme .ui-dialog .ui-dialog-buttonpane {
    border-top: 1px solid #282C30 !important;
    background-color: #30373d ;
}

.LightTheme .ui-dialog .ui-dialog-buttonpane {
    border-top: 1px solid #CCCCCB !important;
    background-color: #E6E7E6;
}

.DarkTheme li label {
    color: white !important;
}

.DarkTheme li svg {
    color: #EFF2F4 !important;
}

.LightTheme .ui-dialog {
    /* background-color: white; */
    border: 1px solid black;
    color: black;
}

.LightTheme .ui-dialog .ui-dialog-buttonpane {
    border-top: 1px solid #f6f6f6;
}

.LightTheme li label {
    color: black !important;
}

.LightTheme li svg {
    color: #881B4C !important;
}

/* to avoid issue of position: relative from the Resizable package */
/* .box {
    position: absolute !important;
} */


div#ui-dialog-buttonpaneKey {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}